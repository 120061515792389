import { useState, useEffect, useContext, useMemo } from "react";
import { Typography, Stack } from "@mui/material";
import { theme } from "../theme";
import { useSubmitAch } from "./useSubmitAch";
import { useForm } from "react-hook-form";
import {
  BillingContext,
  BillingType,
  AchInfo,
} from "../components/BillingProvider";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../components/AddressAndAccountProvider";
import getREMFromPX from "../utils/getREMFromPX";
import Label from "../components/Label";
import Select from "../components/Select";
import SelectOption from "../components/SelectOption";
import Input from "../components/Input";
import ErrorMessage from "../components/ErrorMessage";
import useSetPaymentErrorCode from "./useSetPaymentErrorCode";
import sendErrorToast from "../utils/sendErrorToast";

const useAchForm = (onSuccess?: Function) => {
  const colors = theme["new"].colors;
  const { paymentMethod } = useContext(BillingContext) as BillingType;
  const { currentAccount, provider } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [error, setError] = useState("");

  const operatorName = useMemo(
    () => provider?.data?.provider?.name ?? "the operator",
    [provider]
  );

  const form = useForm<AchInfo>({
    mode: "onChange",
    defaultValues: {
      ownerName: "",
      routingNum: "",
      accountNum: "",
      accountType: "CHECKING",
      accountHolderType: "PERSONAL",
    },
  });

  const errorMessage = useSetPaymentErrorCode(form, error);

  useEffect(() => {
    if (paymentMethod) {
      form.setValue("ownerName", paymentMethod.owner_name);
      form.setValue("routingNum", paymentMethod.routing_number);
      form.setValue(
        "accountNum",
        paymentMethod.type === "us_bank_account" && paymentMethod.last4
          ? `********${paymentMethod.last4}`
          : ""
      );
      form.setValue("accountType", paymentMethod.account_type ?? "CHECKING");
      form.setValue(
        "accountHolderType",
        paymentMethod.account_holder_type ?? "PERSONAL"
      );
    }
  }, [form, paymentMethod]);

  const ownerName = form.watch("ownerName");
  const routingNum = form.watch("routingNum");
  const accountNum = form.watch("accountNum");
  const accountType = form.watch("accountType");
  const accountHolderType = form.watch("accountHolderType");

  const { mutateAsync, isLoading } = useSubmitAch(currentAccount?.id, {
    onError: console.log,
    onSuccess: (response) => {
      if (response?.message?.includes("account number")) {
        form.setError("accountNum", {
          message: "This is not a valid account number.",
        });
      } else if (response?.message?.includes("routing number")) {
        form.setError("routingNum", {
          message: "This routing number is not accurate.",
        });
      } else if (response?.message) {
        setError(response.message);
      }
      onSuccess?.(response);
    },
  });

  const submit = () => {
    if (!currentAccount?.id) {
      sendErrorToast("No account ID provided");
      return;
    }

    let arr: string[] = ["utility"];
    // if(warranty?.insuranceSel)
    //   arr.push("vbg_insurance");

    const achValues = form.getValues();

    const body = {
      owner_name: achValues.ownerName,
      routing_number: achValues.routingNum,
      account_number: achValues.accountNum,
      account_type: achValues.accountType,
      account_holder_type: achValues.accountHolderType,
      items: arr,
      is_default_payment_method: true,
    };

    mutateAsync(body);
  };

  const fields = (
    <>
      {!!errorMessage && (
        <Typography
          fontFamily="Montserrat"
          fontSize={getREMFromPX(14)}
          fontWeight="500"
          fontStyle="normal"
          lineHeight="25px"
          letterSpacing={0.28}
          textAlign="left"
          color={colors.errorRed}
          marginTop="15px"
        >
          {errorMessage}
        </Typography>
      )}
      <Stack
        spacing={getREMFromPX(theme.spacing * 3)}
        mt={getREMFromPX(theme.spacing * 8)}
        width="100%"
      >
        <Stack>
          <Label htmlFor="ownerName">Name</Label>
          <Input
            id="ownerName"
            data-testid="ownerName"
            placeholder=""
            {...form.register("ownerName", {
              required: true,
            })}
            error={!!form.formState.errors.ownerName && !!ownerName?.length}
          />
          {!!form.formState.errors.ownerName && !!ownerName?.length && (
            <ErrorMessage>
              {form.formState.errors.ownerName.message}
            </ErrorMessage>
          )}
        </Stack>
        <Stack>
          <Label htmlFor="routingNum">Routing Number</Label>
          <Input
            data-testid="routingNum"
            placeholder=""
            {...form.register("routingNum", {
              required: true,
              pattern: { value: /^\d{9}$/, message: "Invalid routing number" },
            })}
            error={!!form.formState.errors.routingNum && !!routingNum?.length}
          />
          {!!form.formState.errors.routingNum && !!routingNum?.length && (
            <ErrorMessage>
              {form.formState.errors.routingNum.message}
            </ErrorMessage>
          )}
        </Stack>
        <Stack>
          <Label htmlFor="accountNum">Account Number</Label>
          <Input
            data-testid="accountNum"
            placeholder=""
            {...form.register("accountNum", {
              required: true,
              pattern: {
                value: /^\d{8,17}$/,
                message: "invalid account number",
              },
            })}
            error={!!form.formState.errors.accountNum && !!accountNum?.length}
          />
          {!!form.formState.errors.accountNum && !!accountNum?.length && (
            <ErrorMessage>
              {form.formState.errors.accountNum.message}
            </ErrorMessage>
          )}
        </Stack>
        <Stack direction="row" spacing={getREMFromPX(theme.spacing * 3)}>
          <Stack flex={1}>
            <Label htmlFor="accountType">Account Type</Label>
            <Select
              {...form.register("accountType", { required: true })}
              id="accountType"
              value={accountType}
              onChange={(e) =>
                form.setValue("accountType", e.target.value as string)
              }
            >
              {[
                { key: "Checking", value: "CHECKING" },
                { key: "Savings", value: "SAVINGS" },
              ].map((val, idx) => (
                <SelectOption
                  data-testid={val.key}
                  key={val.key}
                  value={val.value}
                >
                  {val.key}
                </SelectOption>
              ))}
            </Select>
          </Stack>
          <Stack flex={1}>
            <Label htmlFor="accountHolderType">Account Holder Type</Label>
            <Select
              {...form.register("accountHolderType", { required: true })}
              id="accountHolderType"
              value={accountHolderType}
              onChange={(e) =>
                form.setValue("accountHolderType", e.target.value as string)
              }
            >
              {[
                { key: "Personal", value: "PERSONAL" },
                { key: "Business", value: "BUSINESS" },
              ].map((val, idx) => (
                <SelectOption
                  data-testid={val.key}
                  key={val.key}
                  value={val.value}
                >
                  {val.key}
                </SelectOption>
              ))}
            </Select>
          </Stack>
        </Stack>
      </Stack>
    </>
  );

  const getMandate = (buttonText: string = "Save") => {
    const firstParagraph = `By clicking ‘${buttonText}’, you authorize ${operatorName} to debit the bank account
      specified above for any amount owed for charges arising from your use of
      ${operatorName} services and/or purchase of products from ${operatorName}
      pursuant to ${operatorName}’s website and terms, until this authorization is
      revoked. You may amend or cancel this authorization at any time by
      providing notice to ${operatorName} with 30 (thirty) days notice.`;

    const secondParagraph = `If you use ${operatorName}’s services or purchase additional products
      periodically pursuant to ${operatorName}’s terms, you authorize ${operatorName}
      to debit your bank account periodically. Payments that fall outside of
      the regular debits authorized above will only be debited after your
      authorization is obtained.`;

    return (
      <>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(10),
            fontWeight: "normal",
            fontStyle: "normal",
            textAlign: "left",
            lineHeight: "20px",
            color: "#000000",
            marginTop: "25px",
          }}
        >
          {firstParagraph}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(10),
            fontWeight: "normal",
            fontStyle: "normal",
            textAlign: "left",
            lineHeight: "20px",
            color: "#000000",
            marginTop: "15px",
          }}
        >
          {secondParagraph}
        </Typography>
      </>
    );
  };

  return {
    form,
    fields,
    getMandate,
    submit,
    isLoading,
    error,
    setError,
  };
};

export default useAchForm;
