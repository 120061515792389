import { Box, Typography } from "@mui/material";
import getREMFromPX from "../../../../../utils/getREMFromPX";
import { OverviewDataModel } from "../../../../../components/AllServices/AllServices";
import { useEffect } from "react";

import Loader from "../../../../../components/Loader";

const LoaderContent = ({
  modalOverviewData,
}: {
  modalOverviewData: OverviewDataModel;
}) => {
  useEffect(() => {
    const redirectToNewTabAfterDelay = () => {
      setTimeout(() => {
        if (modalOverviewData.subscription_redirect_url) {
          window.open(modalOverviewData.subscription_redirect_url, "_blank");
        }
      }, 2000);
    };

    redirectToNewTabAfterDelay();
  }, [modalOverviewData.subscription_redirect_url]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(32)}
        fontFamily="Montserrat"
        color="#253331"
        fontWeight="bold"
        pt={"95px"}
      >
        You are now subscribed to
      </Typography>
      <img
        src={`${process.env.PUBLIC_URL}/assets/provider/${modalOverviewData.provider_id}.png`}
        alt=""
      />
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(20)}
        fontFamily="Montserrat"
        color="#253331"
        fontWeight="600"
        pt={"25px"}
      >
        {modalOverviewData.fields.Name}
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <Loader />
      </Box>
      <Typography
        textAlign="left"
        fontSize={getREMFromPX(20)}
        fontFamily="Montserrat"
        color="#253331"
        fontWeight="500"
        mt={"39px"}
      >
        You are now being redirected to{" "}
        {modalOverviewData.providerName || "the provider"}'s website
      </Typography>
    </Box>
  );
};

export default LoaderContent;
