import { Stack, Grid, colors, Box } from "@mui/material";

import EmptyMyServicesMessage from "./EmptyMyServicesMessage";
import CardWrapper from "../CardWrapper";
import { ForwardArrowIcon } from "../../assets/icons";
import { Link } from "react-router-dom";
import { routes } from "../../App";
import MyServicesTile from "./MyServicesTile";
import getREMFromPX from "../../utils/getREMFromPX";

export interface CurrentSubscription {
  serviceType: string;
  serviceName: string;
  serviceLogo: string;
  devicePort: string;
  deviceName: string;
}

interface MyServicesCardProps {
  subscriptions: Array<CurrentSubscription>;
}

const MyServicesCard = ({ subscriptions }: MyServicesCardProps) => {
  const arrowAction = (
    <Link
      to={`${routes.services.path}?tab=My Services`}
      aria-label="View your subscriptions"
      data-cy="dashboard-my-services"
    >
      <ForwardArrowIcon />
    </Link>
  );

  return (
    <Grid item xl={12}>
      <CardWrapper
        sx={{
          bgcolor: colors.lightGreen,
        }}
        title="My Services"
        actions={arrowAction}
      >
        <Box
          width="100%"
          sx={{
            mt: "1rem",
            display: "flex",
            flexDirection: {
              md: "column",
              sm: "row",
              xs: "column",
            },
            alignItems: "center",
            gap: "14px",
            overflow: "auto",
            rowGap: 2,
            columnGap: 2,
            pb: 2,
            height: {
              lg: getREMFromPX(393),
              sm: "auto",
              xs: getREMFromPX(393),
            },
            overflowY: "auto",
          }}
        >
          {subscriptions?.length > 0 ? (
            subscriptions?.map((subscription: any, index: number) => (
              <MyServicesTile
                key={`${index}-${subscription.id}`}
                subscription={subscription}
              />
            ))
          ) : (
            <EmptyMyServicesMessage />
          )}
        </Box>
      </CardWrapper>
    </Grid>
  );
};

export default MyServicesCard;
