import { Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";
import {
  AuthContext,
  AuthenticationContext,
} from "../../components/AuthProvider";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import { stepperContainerStyle } from "./Styles";
import useViewport from "../../hooks/useViewport";
import CityIcon from "../../components/CityIcon";
import CompletedWorkflow from "./components/CompletedWorkflow";

const steps = [...Array(3).keys()];

interface IObjectKeys {
  [key: number]: JSX.Element;
}

export type Step1Type = {
  userName: string;
};

export type Step2Type = {
  questions: {
    question: string;
    id: string;
    answer: string;
  }[];
};

export type Step3Type = {
  newPassword: string;
  confirmPassword: string;
};

const ForgotPassword = () => {
  const { isAuthenticated } = useContext(AuthContext) as AuthenticationContext;
  const navigate = useNavigate();
  const { width, isMobile } = useViewport(600);
  const colors = theme["new"].colors;
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    step1: {
      userName: "",
    },
    step2: {
      questions: [
        {
          question: "",
          id: "",
          answer: "",
        },
        {
          question: "",
          id: "",
          answer: "",
        },
      ],
    },
    step3: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  const changeStep = (incomingValues?: Step1Type | Step2Type) => {
    const newStep = activeStep + 1;
    if (incomingValues) {
      setFormValues((formValues) => ({
        ...formValues,
        [`step${newStep}`]: incomingValues,
      }));
    }
    setActiveStep(newStep);
  };

  const goBackOneStep = () => {
    if (activeStep === 0) {
      return navigate(-1);
    }

    setActiveStep(activeStep - 1);
  };

  const stepsComponents = {
    0: (
      <Step1
        changeStep={changeStep}
        goBackOneStep={goBackOneStep}
        previousValues={formValues}
      />
    ),
    1: (
      <Step2
        changeStep={changeStep}
        goBackOneStep={goBackOneStep}
        previousValues={formValues}
      />
    ),
    2: (
      <Step3
        changeStep={changeStep}
        goBackOneStep={goBackOneStep}
        previousValues={formValues}
      />
    ),
  } as IObjectKeys;

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.dashboard.path);
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (activeStep === steps.length) {
      setTimeout(() => navigate(routes.login.path), 5000);
    }
  }, [activeStep, navigate]);

  return (
    <Stack
      height="100vh"
      flexDirection="row"
      flex={1}
      justifyContent="center"
      alignItems="flex-start"
      pt={width < 1220 ? "48px" : "110px"}
      bgcolor={colors.white}
      margin="auto"
    >
      <Stack
        flex={1}
        maxWidth={isMobile ? `${width}px` : "640px"}
        alignItems="center"
      >
        <div style={stepperContainerStyle(isMobile)}>
          <Stepper activeStep={activeStep}>
            {steps.map((step) => (
              <Step key={step}>
                <StepLabel />
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep < Object.keys(stepsComponents).length ? (
          <>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: isMobile ? getREMFromPX(30) : getREMFromPX(36),
                fontWeight: 600,
                lineHeight: "44px",
                letterSpacing: "0em",
                marginBottom: isMobile ? "23.6px" : "1.5rem",
                textAlign: "center",
              }}
            >
              Account Recovery
            </Typography>
            {width >= 600 && (
              <div
                style={{
                  position: "absolute",
                  left: width < 1220 ? "47px" : "72px",
                  top: width < 1220 ? "38px" : "56px",
                }}
              >
                <CityIcon
                  style={{
                    width: getREMFromPX(theme.spacing * 20),
                    height: "auto",
                  }}
                />
              </div>
            )}
            {stepsComponents[activeStep]}
          </>
        ) : (
          <CompletedWorkflow />
        )}
      </Stack>
    </Stack>
  );
};

export default ForgotPassword;
