import { useCallback, useEffect, useMemo, useState } from "react";
import CardService, {
  LogoContainer,
} from "../../routes/Services/components/CardService";
import {
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ListCardMobile from "../../routes/Services/components/ListCardMobile";
import useViewport from "../../hooks/useViewport";
import { Plan } from "../../routes/Services/utils/intefaces/Plan";
import { FieldIcon } from "../../routes/Services/utils/intefaces/FieldIcon";
import WorkflowSubscriptionModal from "../../routes/Services/components/SubscriptionModal/WorkflowSubscriptionModal";
import SubscribeModal from "../../routes/Services/components/SubscribeModal";
import TableServices from "./TableServices";
import { typesOptions } from "./constants";
import { SortFilterLabel } from "./interfaces";
import { applyExtraFilters } from "../../routes/Services/utils/extraFIlters";

import { renderStars } from "../../routes/Services/utils/getStars";
import { theme } from "../../theme";
import { useSearchParams } from "react-router-dom";
import Button from "../Button";
import { useGetConfig } from "../../hooks/useGetConfig";

export const onSubscribeService = (param: Partial<OverviewDataModel>) => {
  console.log(param);
};

interface AllServicesProps {
  plansList: Array<Plan>;
}

export interface OverviewDataModel {
  name: string;
  planName: string;
  providerName: string;
  service_id?: string;
  provider_id: string; //used for img
  fields: {
    Down: string;
    LinkSpeed: number;
    Name: string; // same than plan name
    Price: string;
    Up: string;
  };
  description: string;
  planId: string; // used to get reviews
  ratings_sum?: string;
  total_reviews?: string;
  email?: string;
  phone?: string;
  support_url?: string;
  subscription_redirect_url?: string;
  fields_icons?: Array<FieldIcon>;
}

const initial_state = {
  fields: {
    Down: "",
    LinkSpeed: 0,
    Name: "",
    Price: "",
    Up: "",
  },
  description: "",
  name: "",
  planName: "",
  provider_id: "",
  planId: "",
  ratings_sum: "",
  total_reviews: "",
  fields_icons: [],
  providerName: "",
};

const CardContainer = styled("div")`
  margin-top: 43.5px;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem 1rem;
  justify-content: flex-start;
`;

const AllServices = ({ plansList }: AllServicesProps) => {
  const { isMobile } = useViewport();
  const [filteredPlansList, setFilteredPlansList] =
    useState<Array<Plan>>(plansList);

  const [urlSearchParams] = useSearchParams();
  const filterBy = decodeURI(urlSearchParams.get("filter") ?? "");

  const [sortFilter, setSortFilter] = useState<SortFilterLabel | null>(null);
  const [options, setOptions] = useState(typesOptions);
  const [selectedTypeServiceFilter, setSelectedTypeServiceFilter] =
    useState<string>(filterBy ? filterBy : "All Services");

  const { data } = useGetConfig();

  const isUserRatingEnabled = Boolean(
    data?.data.config.enabled_features.USER_REVIEWS.enabled
  );

  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [openWorkflowSubscriptionModal, setOpenWorkflowSubscriptionModal] =
    useState(false);
  const [modalOverviewData, setModalOverviewData] = useState<OverviewDataModel>(
    () => initial_state
  );

  function handleSetModalOverviewData(plan: Plan) {
    const data = {
      fields: {
        Down: plan.fields?.Down as string,
        LinkSpeed: plan.fields?.LinkSpeed as number,
        Name: plan.fields?.Name as string,
        Price: plan.fields?.Price as string,
        Up: plan.fields?.Up as string,
      },
      description: plan.description as string,
      providerName: plan.providerName as string,
      name: plan.name as string,
      planName: plan.planName as string,
      service_id: plan.service_id,
      provider_id: plan.providerId as string,
      planId: plan.id as string,
      ratings_sum: plan.ratings_sum as string,
      total_reviews: plan.total_reviews as string,
      email: plan.Contacts[0]?.email,
      phone: plan.Contacts[0]?.phone,
      support_url: plan.support_url || plan.Contacts[0]?.support_url,
      subscription_redirect_url: plan.subscription_redirect_url,
      fields_icons: plan.fields_icons as Array<FieldIcon>,
    };
    setModalOverviewData(data);
  }

  const sortedAndFilteredPlans = useMemo(() => {
    return applyExtraFilters(
      filteredPlansList,
      selectedTypeServiceFilter,
      sortFilter
    );
  }, [sortFilter, selectedTypeServiceFilter, filteredPlansList]);

  const handleChangeTypeOfView = (idx: number) => {
    const changedStatusList = options.map((val, i) => ({
      ...val,
      active: i === idx,
    }));

    setOptions(changedStatusList);
  };

  const handleInputChange = useCallback(
    (val: string | null = null) => {
      let filteredPlans: Plan[] = plansList;
      if (val) {
        filteredPlans = plansList.filter(
          (v: Plan) =>
            v.planName.toLowerCase().includes(val.toLowerCase()) ||
            v.name.toLowerCase().includes(val.toLowerCase()) ||
            (v.providerName &&
              v.providerName.toLowerCase().includes(val.toLowerCase())) ||
            (v.fields &&
              v.fields.Price &&
              v.fields.Price.toLowerCase().includes(val.toLowerCase()))
        );
      }
      setFilteredPlansList(filteredPlans);
    },
    [plansList]
  );

  useEffect(() => {
    handleInputChange();
  }, [handleInputChange]);

  const calcStarsAverage = (ratings_sum: string, total_reviews: string) => {
    const reviewsAverage =
      ratings_sum && Number(total_reviews) !== 0
        ? (Number(ratings_sum) / Number(total_reviews)).toString()
        : "0";
    return renderStars(reviewsAverage);
  };

  function renderContent() {
    if (isMobile) {
      return (
        <Box
          sx={{
            overflow: "auto",
          }}
          data-cy="allServicesContainerBox"
        >
          {sortedAndFilteredPlans?.map((plan) => (
            <Grid item xs={12} key={plan.id}>
              <ListCardMobile
                setOpenSubscribeModal={() => setOpenSubscribeModal(true)}
                setOpenWorkflowSubscriptionModal={() =>
                  setOpenWorkflowSubscriptionModal(true)
                }
                plan={plan}
                setModalOverviewData={setModalOverviewData}
              />
            </Grid>
          ))}
        </Box>
      );
    }

    // if user select tile/card view
    // by default we have tile/card view selected
    if (options.at(0)?.active) {
      return (
        <CardContainer data-cy="allServicesContainer">
          {sortedAndFilteredPlans?.map((plan, idx) => (
            <CardService
              key={`${plan.id}`}
              setOpenSubscribeModal={() => setOpenSubscribeModal(true)}
              plan={plan}
              setModalOverviewData={setModalOverviewData}
              setOpenWorkflowSubscriptionModal={() =>
                setOpenWorkflowSubscriptionModal(true)
              }
              dataCy={`card-service-${idx}`}
            />
          ))}
        </CardContainer>
      );
    }

    // if user select table/list view
    return (
      <Box
        sx={{
          marginTop: "38px",
          marginLeft: "32px",
          marginRight: "12px",
          overflow: "scroll",
          overflowY: "hidden",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "left" }}>Provider</TableCell>
              <TableCell style={{ textAlign: "left" }}>Name</TableCell>
              <TableCell style={{ textAlign: "left" }}>Type</TableCell>
              {selectedTypeServiceFilter === "Internet Services" &&
                window.innerWidth >= 1140 && (
                  <>
                    <TableCell style={{ textAlign: "left" }}>Down</TableCell>
                    <TableCell style={{ textAlign: "left" }}>Up</TableCell>
                  </>
                )}
              <TableCell style={{ textAlign: "left" }}>Price</TableCell>
              {isUserRatingEnabled ? (
                <TableCell style={{ textAlign: "left" }}>
                  Average Rating
                </TableCell>
              ) : null}
              <TableCell align="center">Details</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAndFilteredPlans.map((plan, index) => {
              const stars = calcStarsAverage(
                plan.ratings_sum,
                plan.total_reviews
              );
              const cyRow = `service-table-row-${index}`;
              return (
                <TableRow
                  key={index}
                  style={{
                    cursor: "pointer",
                  }}
                  data-cy={cyRow}
                >
                  <TableCell
                    style={{ textAlign: "left", padding: 0 }}
                    data-cy={`${cyRow}-provider`}
                  >
                    <LogoContainer width="140" height="63">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/provider/${plan.providerId}.png`}
                        alt=""
                      />
                    </LogoContainer>
                  </TableCell>
                  <TableCell align="left" data-cy={`${cyRow}-name`}>
                    {plan.planName}
                  </TableCell>
                  <TableCell align="left" data-cy={`${cyRow}-type`}>
                    {plan.name}
                  </TableCell>
                  {selectedTypeServiceFilter === "Internet Services" &&
                    window.innerWidth >= 1140 && (
                      <>
                        <TableCell align="left" data-cy={`${cyRow}-down`}>
                          {plan.fields?.Down}
                        </TableCell>
                        <TableCell align="left" data-cy={`${cyRow}-up`}>
                          {plan.fields?.Up}
                        </TableCell>
                      </>
                    )}
                  <TableCell align="left" data-cy={`${cyRow}-price`}>
                    ${plan.fields?.Price}
                  </TableCell>
                  {isUserRatingEnabled ? (
                    <TableCell align="left" data-cy={`${cyRow}-rating`}>
                      {stars}
                    </TableCell>
                  ) : null}
                  <TableCell align="center" data-cy={`${cyRow}-details`}>
                    <Box
                      component="button"
                      onClick={() => {
                        handleSetModalOverviewData(plan);
                        setOpenSubscribeModal(true);
                      }}
                      sx={{
                        width: "100px",
                        height: "20px",
                        fontSize: "12px",
                        padding: 0,
                        border: "none",
                        backgroundColor: "transparent",
                        color: "#1D19E0",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      data-cy={`${cyRow}-view-more`}
                    >
                      View More
                    </Box>
                  </TableCell>
                  <TableCell align="left" data-cy={`${cyRow}-action`}>
                    <Button
                      text="Subscribe"
                      onClick={() => {
                        handleSetModalOverviewData(plan);
                        setOpenWorkflowSubscriptionModal(true);
                      }}
                      sx={{
                        width: "120px",
                        height: "20px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        background: "white",
                        padding: "14px 10px",
                        margin: "auto",
                        border: "1px solid #D0D5DD",
                        color: theme.new.colors.darkGreenFontColor,
                        textTransform: "initial",
                        ":hover": {
                          border: "1px solid #D0D5DD",
                          background: "white",
                        },
                        fontWeight: 600,
                      }}
                      data-cy={`${cyRow}-subscribe`}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }

  return (
    <TableServices
      options={options}
      handleChangeTypeOfView={handleChangeTypeOfView}
      setSelectedTypeServiceFilter={setSelectedTypeServiceFilter}
      setSortFilter={setSortFilter}
      sortFilter={sortFilter}
      handleInputChange={handleInputChange}
      isMyTabsActive={true}
      activeServiceFilter={filterBy}
    >
      <>
        {renderContent()}
        <SubscribeModal
          isUserRatingEnabled={isUserRatingEnabled}
          open={openSubscribeModal}
          handleClose={() => setOpenSubscribeModal(false)}
          modalOverviewData={modalOverviewData}
          setOpenWorkflowSubscriptionModal={setOpenWorkflowSubscriptionModal}
        />
        <WorkflowSubscriptionModal
          open={openWorkflowSubscriptionModal}
          modalOverviewData={modalOverviewData}
          handleClose={() => {
            setModalOverviewData(initial_state);
            setOpenWorkflowSubscriptionModal(false);
            setOpenSubscribeModal(false);
          }}
        />
      </>
    </TableServices>
  );
};

export default AllServices;
