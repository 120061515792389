import { Typography, Stack } from "@mui/material";
import { SystemProps, TypographyProps } from "@mui/system";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import { ENTRYPOINT_LINK } from "../../api/constants/entryPointLink";
import ExternalLink from "../ExternalLink";
import useViewport from "../../hooks/useViewport";
import getREMFromPX from "../../utils/getREMFromPX";
import { useContext } from "react";
import { AuthContext, AuthenticationContext } from "../AuthProvider";

const EntryPointLink = ({ ...props }: TypographyProps & SystemProps) => {
  const themePath = useGetThemePath();
  const { isMobile } = useViewport(835);
  const { isAuthenticated } = useContext(AuthContext) as AuthenticationContext;

  // Floating center powered by entrypoint badge is only on mobile and on non authenticated pages
  // for authenticated pages, the powered by logo is on the bottom of left sidebar
  if (isAuthenticated && !isMobile) return null;

  return (
    <Stack
      sx={{
        alignItems: "center",
        paddingBottom: "1rem",
        borderRadius: "50px",
        position: "fixed",
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "fit-content",
      }}
    >
      <Stack
        sx={{
          padding: "0.5rem 1.5rem",
          backgroundColor: "white",
          borderRadius: "50px",
          boxShadow: "0 4px 25px rgba(201, 218, 216, 0.6)",
        }}
      >
        <Typography
          fontWeight={theme.fonts.weights.bolder}
          fontSize={isMobile ? getREMFromPX(9) : getREMFromPX(11)}
          color={theme[themePath].colors.links.entgreen}
          textAlign="center"
          fontFamily="Montserrat"
          {...props}
        >
          Powered By&nbsp;
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontFamily: "Montserrat",
              color: theme[themePath].colors.links.teal,
              fontSize: isMobile ? getREMFromPX(9) : getREMFromPX(11),
              fontWeight: theme.fonts.weights.bolder,
            }}
            size="medium"
            href={ENTRYPOINT_LINK}
          >
            EntryPoint Networks
          </ExternalLink>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default EntryPointLink;
