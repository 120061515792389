import { styled } from "@mui/material";
import { theme } from "../../../../theme";
import { Link } from "react-router-dom";

export const VbgTitle = styled("p")`
  margin: 42.4px 4px 17px 16px;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${theme.new.colors.darkGreen};

  @media (max-width: 720px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

export const Location = styled("div")`
  width: 24px;
  height: 24px;
  color: ${theme.new.colors.teal};
`;

export const VbgInfo = styled("div")`
  height: 28px;
  flex-grow: 0;
  margin-left: 16px;
`;

export const StreetInfo = styled("span")`
  width: 128px;
  height: 25px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  margin-top: 4px;
  color: ${theme.new.colors.darkGreen};

  @media (max-width: 720px) {
    font-size: 12px;
    margin-top: 8px;
  }
`;

export const Switch = styled("span")`
  width: 24px;
  height: 24px;
  flex-grow: 0;
  margin: 0 0 3px 8px;
  object-fit: contain;
`;

export const Router = styled("span")`
  width: 24px;
  height: 24px;
  color: ${theme.new.colors.teal};
`;

export const Hardware = styled("div")`
  margin-top: 6px;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: ${theme.new.colors.darkGreen};

  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

export const MyService = styled("span")`
  margin: 0 0 24px;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.new.colors.darkGreen};
  float: left;
`;

export const ViewMyService = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.new.colors.deepBlack};
  text-decoration: none;
  float: right;
  cursor: pointer;
  border: 1px solid #d0d5dd;
  background: #ffffff;

  padding: 2px 14px;
  border-radius: 4px;

  box-shadow: 0px 1px 2px 0px #1018280d;
`;

export const MyServiceRow = styled("div")`
  margin-top: 40px;
  margin-left: 16px;
`;
