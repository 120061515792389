import { useContext } from "react";
import { Typography } from "@mui/material";
import defaultImg from "../../assets/EPLogoColor.svg";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";

const CityIcon = ({ ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const { provider } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const handleError = (e: any) => {
    if (!e.target.src.endsWith(defaultImg)) {
      e.target.src = defaultImg;
    }
  };

  const imageAltText = provider
    ? `${provider?.data?.provider?.name} (${
        provider?.data?.provider?.Contacts?.at(0)?.Location?.city
      })`
    : "city_logo";

  return (
    <Typography
      variant="h1"
      sx={{
        marginBottom: "8px",
      }}
    >
      <img
        {...props}
        src={`${process.env.PUBLIC_URL}/assets/provider/${process.env.REACT_APP_GUID}.png`}
        alt={imageAltText}
        onError={handleError}
      />
    </Typography>
  );
};

export default CityIcon;
