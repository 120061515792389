import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import getREMFromPX from "../../../utils/getREMFromPX";
import useGetThemePath from "../../../hooks/useGetThemePath";
import { theme } from "../../../theme";
import Button from "../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";

const UnsubscribePopup = ({
  open,
  onClose,
  onClickSubscribe,
  isLoading = false,
  name,
  price,
  selectedId,
}: {
  open: boolean;
  onClose: () => void;
  onClickSubscribe: () => void;
  isLoading: boolean;
  name: string;
  price: string;
  selectedId: string;
}) => {
  const themePath = useGetThemePath();
  return (
    <>
      <Modal open={open && !isLoading} onClose={onClose}>
        <Box
          width={295}
          borderRadius="10px"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: theme[themePath].colors.white,
            padding: "8px 3px 16px 2px",
          }}
        >
          <IconButton
            style={{ position: "absolute", top: "3px", right: "3px" }}
            onClick={onClose}
            sx={{
              color: "#488b5b",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            fontSize={getREMFromPX(18)}
            fontWeight={600}
            color={theme.new.colors.darkGreenFontColor}
            textAlign="center"
            paddingTop="28px"
          >
            Are you sure you want to unsubscribe?
          </Typography>
          <Box display="flex" justifyContent="center">
            <img
              width="181px"
              src={`${process.env.PUBLIC_URL}/assets/provider/${selectedId}.png`}
              alt={name}
            />
          </Box>
          <Typography
            fontSize={getREMFromPX(14)}
            textAlign="center"
            fontFamily="Montserrat"
            color={theme.new.colors.darkGreenFontColor}
            fontWeight="600"
          >
            {name}
          </Typography>
          <Typography
            fontSize={getREMFromPX(14)}
            textAlign="center"
            fontFamily="Montserrat"
            color={theme.new.colors.darkGreenFontColor}
            fontWeight="500"
            marginTop="9px"
          >
            ${price}
          </Typography>
          <Stack display="flex" justifyContent="center">
            <Button
              text="Unsubscribe"
              mode="outline"
              sx={{
                width: "156px",
                height: "38px",
                fontSize: getREMFromPX(12),
                fontWeight: "bold",
                margin: "16px auto 0",
              }}
              onClick={onClickSubscribe}
            />
          </Stack>
        </Box>
      </Modal>
      <Modal open={isLoading} onClose={() => {}}>
        <Box
          width={499}
          height={224}
          borderRadius="10px"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: theme[themePath].colors.white,
            padding: "9px 3px 7px 2px",
          }}
        >
          <Typography
            fontSize="1.125rem"
            fontWeight={600}
            color={theme.new.colors.darkGreenFontColor}
            textAlign="center"
          >
            <CircularProgress
              size="4rem"
              style={{ color: "#488B5B", margin: "1.5rem" }}
            />
            <br />
            Unsubscribing...
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default UnsubscribePopup;
