import { Gradient } from "@mui/icons-material";

export const theme: {
  [key: string]: any;
} = {
  spacing: 4,
  /* Note: fontFamily should NOT be edited unless the new font is installed first */
  fontFamily: "Nunito Sans, Roboto, sans-serif",
  fonts: {
    weights: {
      normal: 400,
      bold: 500,
      mediumBold: 600,
      bolder: 700,
    },
  },
  backgrounds: {
    main: "#F6F6F6",
    widget: "#FFFFFF",
    table: "#FAFAFA",
    primarySelection: "#F8FCF9",
    secondarySelection: "#F7FAFD",
    tertiarySelection: "#e6e6e6",
  },
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.04), inset 0px 0px 1px #BABABF",
  new: {
    colors: {
      formGrey: "#6d6d6d",
      subtitleGrey: "#12373f8a",
      darkGreen: "#253331",
      formGreyDark: "#434343",
      yellow: "#fce8b6",
      lightGreen: "#d7f1da",
      deepBlack: "#434343",
      deepDarkGreen: "#416661",
      minimalGrey: "#e8e8e8",
      green: "#488b5b",
      darkGreenFontColor: "#253331",
      grey: "#c5c5c5",
      errorRed: "#ec0000",
      white: "#fff",
      brightGreen: "#84e88e",
      lightGrey: "#efefef",
      lightestGreen: "#fbfffe",
      pink: "#ffd2d2",
      mediumGreen: "#02575c",
      notificationOrange: "#ff7a00",
      teal: "#55857e",
      softGreen: "#f0fbf2",
      dustyGreen: "#c5dcc8",
      softGrey: "#f0f0f0",
      mediumGrey: "#a9a9a9",
      darkRed: "#d80d0d",
      darkOrange: "#dd6a00",
      starYellow: "#f9cb28",
      lightWarningStatus: "#F84D04",
      strongWarningStatus: "#E53934",
      okStatus: "#4CAF81",
      dark: "#000000",
      red: "red",
      externalLink: "#0299FD",
      finalStepperFormIconColor: "#04A7F8",
      lightBorder: "#E4E7E5",
      hoverColor: "#f9f9f9",
      primary: {
        900: "#1E3E26",
        700: "#2E613B",
        500: "#3F8450",
        300: "#97CEA5",
        50: "#EEF7F0",
      },
      secondary: {
        900: "#0F2034",
        700: "#1A395B",
        500: "#255183",
        300: "#88B0DD",
        50: "#EBF2F9",
      },
      textPrimary: {
        primary: "#303632",
        secondary: "#616B64",
        placeholder: "#79867D",
        disabled: "#AFB6B1",
      },
      textSecondary: {
        primary: "#FFFFFF",
        secondary: "#F2F3F2",
        placeholder: "#AFB6B1",
        disabled: "#79867D",
      },
    },
    gradients: {
      greenMenuGradient: "linear-gradient(180deg, #cdefd4 0%, #7ab8b0 87%)",
    },
    boxShadows: {
      boxShadowBR: "8px 8px 10px -1px rgba(0,0,0,0.20)",
    },
  },
  default: {
    colors: {
      formGrey: "#6d6d6d",
      subtitleGrey: "#12373f8a",
      darkGreen: "#253331",
      formGreyDark: "#434343",
      yellow: "#fce8b6",
      lightGreen: "#d7f1da",
      deepBlack: "#434343",
      minimalGrey: "#e8e8e8",
      green: "#488b5b",
      darkGreenFontColor: "#253331",
      grey: "#c5c5c5",
      errorRed: "#ec0000",
      white: "#fff",
      brightGreen: "#84e88e",
      lightGrey: "#efefef",
      lightestGreen: "#fbfffe",
      pink: "#ffd2d2",
      mediumGreen: "#02575c",
      notificationOrange: "#ff7a00",
      teal: "#55857e",
      softGreen: "#f0fbf2",
      dustyGreen: "#c5dcc8",
      softGrey: "#f0f0f0",
      mediumGrey: "#a9a9a9",
      darkRed: "#d80d0d",
      darkOrange: "#dd6a00",
      starYellow: "#f9cb28",
      lightWarningStatus: "#F84D04",
      strongWarningStatus: "#E53934",
      okStatus: "#4CAF81",
      dark: "#000000",
      red: "red",
      externalLink: "#0299FD",
      finalStepperFormIconColor: "#04A7F8",
      lightBorder: "#E4E7E5",
      hoverColor: "#f9f9f9",
      primary: {
        900: "#1E3E26",
        700: "#2E613B",
        500: "#3F8450",
        300: "#97CEA5",
        50: "#EEF7F0",
      },
      secondary: {
        900: "#0F2034",
        700: "#1A395B",
        500: "#255183",
        300: "#88B0DD",
        50: "#EBF2F9",
      },
      textPrimary: {
        primary: "#303632",
        secondary: "#616B64",
        placeholder: "#79867D",
        disabled: "#AFB6B1",
        heading: "#253331",
        headingAlt: "#55857e",
      },
      textSecondary: {
        primary: "#FFFFFF",
        secondary: "#F2F3F2",
        placeholder: "#AFB6B1",
        disabled: "#79867D",
      },
      buttons: {
        primary: {
          ButtonBg: "#55857e",
          HvButtonBg: "#55857e",
          ActiveButtonBg: "#02575c",
          BtText: "#ffffff",
        },
        disabled: {
          ButtonBg: "#E2E2E2",
          HvButtonBg: "#E2E2E2",
          ActiveButtonBg: "#E2E2E2",
          BtText: "rgb(109, 109, 109)",
        },
        selectButton: {
          ButtonBg: "#55857e",
          HvButtonBg: "#55857e",
          ActiveButtonBg: "#02575c",
          BtText: "#ffffff",
          borderColor: "transparent",
          disabled: {
            ButtonBg: "#E2E2E2",
            HvButtonBg: "#E2E2E2",
            ActiveButtonBg: "#E2E2E2",
            BtText: "rgb(109, 109, 109)",
          },
        },
      },
      links: {
        primary: "#497C76",
        hoverColor: "#497C76",
        externalLink: "#0299FD",
        entgreen: "#253331",
        teal: "#55857e",
      },
      backgrounds: {
        default: "#fbfffe",
        fallback: "#7ab8b0",
        asideGradient: "linear-gradient(180deg, #cdefd4 0%, #7ab8b0 87%)",
      },
      icons: {
        default: {
          fillColor: "#55857e",
        },
        viewBy: {
          defaultBg: "transparent",
          activeBg: "#d7f1da",
          hoverBg: "#e8e8e8",
        },
      },
      stepper: {
        activeBg: "#02575C",
        activeColor: "#ffffff",
        completeBg: "#d7f1da",
        completeColor: "#000",
        defaultBg: "#c5c5c5",
        defaultColor: "#000",
        fillColor: "#ffffff",
      },
    },
    gradients: {
      greenMenuGradient: "linear-gradient(180deg, #cdefd4 0%, #7ab8b0 87%)",
    },
    boxShadows: {
      boxShadowBR: "8px 8px 10px -1px rgba(0,0,0,0.20)",
    },
  },
  orangeburg: {
    colors: {
      formGrey: "#6d6d6d",
      subtitleGrey: "#12373f8a",
      darkGreen: "#253331",
      formGreyDark: "#434343",
      yellow: "#fce8b6",
      lightGreen: "#d7f1da",
      deepBlack: "#434343",
      minimalGrey: "#e8e8e8",
      green: "#488b5b",
      darkGreenFontColor: "#253331",
      grey: "#c5c5c5",
      errorRed: "#ec0000",
      white: "#fff",
      brightGreen: "#84e88e",
      lightGrey: "#efefef",
      lightestGreen: "#fbfffe",
      pink: "#ffd2d2",
      mediumGreen: "#02575c",
      notificationOrange: "#ff7a00",
      teal: "#55857e",
      softGreen: "#f0fbf2",
      dustyGreen: "#c5dcc8",
      softGrey: "#f0f0f0",
      mediumGrey: "#a9a9a9",
      darkRed: "#d80d0d",
      darkOrange: "#dd6a00",
      starYellow: "#f9cb28",
      lightWarningStatus: "#F84D04",
      strongWarningStatus: "#E53934",
      okStatus: "#4CAF81",
      dark: "#000000",
      red: "red",
      externalLink: "#0299FD",
      finalStepperFormIconColor: "#04A7F8",
      lightBorder: "#E4E7E5",
      hoverColor: "#DE5A00",
      primary: {
        900: "#5D2600",
        700: "#DE5A00",
        500: "#DE5A00",
        300: "#DE5A00",
        50: "#FFE0CA",
      },
      secondary: {
        900: "#5D2600",
        700: "#DE5A00",
        500: "#DE5A00",
        300: "#DE5A00",
        50: "#FFE0CA",
      },
      textPrimary: {
        primary: "#303632",
        secondary: "#616B64",
        placeholder: "#79867D",
        disabled: "#AFB6B1",
        heading: "#190A00",
        headingAlt: "#DE5A00",
      },
      textSecondary: {
        primary: "#FFFFFF",
        secondary: "#F2F3F2",
        placeholder: "#AFB6B1",
        disabled: "#79867D",
      },
      buttons: {
        primary: {
          ButtonBg: "#DE5A00",
          HvButtonBg: "#DE5A00",
          ActiveButtonBg: "#DE5A00",
          BtText: "#ffffff",
          borderColor: "55857e",
        },
        disabled: {
          ButtonBg: "rgb(226, 226, 226)",
          HvButtonBg: "#E2E2E2",
          ActiveButtonBg: "#E2E2E2",
          BtText: "rgb(109, 109, 109)",
        },
        selectButton: {
          ButtonBg: "#DE5A00",
          HvButtonBg: "#DE5A00",
          ActiveButtonBg: "#DE5A00",
          BtText: "#ffffff",
          borderColor: "transparent",
          disabled: {
            ButtonBg: "#E2E2E2",
            HvButtonBg: "#E2E2E2",
            ActiveButtonBg: "#E2E2E2",
            BtText: "rgb(109, 109, 109)",
          },
        },
      },
      links: {
        primary: "#DE5A00",
        hoverColor: "#DE5A00",
        externalLink: "#0299FD",
        entgreen: "#253331",
        teal: "#55857e",
      },
      backgrounds: {
        default: "#ffffff",
        fallback: "#CDDDEC",
        asideGradient: "linear-gradient(180deg, #CDDDEC 0%, #CDDDEC 87%)",
      },
      icons: {
        default: {
          fillColor: "#E57B33",
        },
        viewBy: {
          defaultBg: "transparent",
          defaultColor: "",
          activeBg: "#E57B33",
          activeColor: "#ffffff",
          hoverBg: "#e8e8e8",
        },
      },
      stepper: {
        activeBg: "#E57B33 ",
        activeColor: "#ffffff",
        completeBg: "#FFE0CA",
        completeColor: "#000",
        defaultBg: "#c5c5c5",
        defaultColor: "#000",
        fillColor: "#ffffff",
      },
    },
    gradients: {
      greenMenuGradient: "linear-gradient(180deg, #cdefd4 0%, #7ab8b0 87%)",
    },
    boxShadows: {
      boxShadowBR: "8px 8px 10px -1px rgba(0,0,0,0.20)",
    },
  },
  wraps: "wrap !important",
} as const;
