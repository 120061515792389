import { Fragment } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import { MenuItemsFields } from "./NavContainer";
import CityIcon from "../../components/CityIcon";
import { Link } from "react-router-dom";

type NavBarProps = {
  title: string;
  menuItems: Array<MenuItemsFields>;
};

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 7.5rem;
  padding-bottom: 2.5rem;
`;

const NavButton = ({ icon, current, title, route }: any) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const cyText = `nav-${title.toLowerCase()}`;
  return (
    <Box component="li">
      <StyledLink to={route.path} data-cy={cyText}>
        <Stack
          component="span"
          bgcolor={current ? colors.backgrounds.default : "transparent"}
          height="3.5rem"
          width="10.5rem"
          left="0.875rem"
          borderRadius="1.75rem"
          alignItems="center"
          pl="1.875rem"
          ml="0.875rem"
          flexDirection="row"
          overflow="visible"
        >
          {icon}
          {current && (
            <Typography
              fontFamily="Montserrat"
              fontSize="1rem"
              fontWeight="bold"
              fontStyle="normal"
              textAlign="left"
              color={colors.darkGreen}
              ml="2.625rem"
              position="absolute"
            >
              {title}
            </Typography>
          )}
        </Stack>
        <Typography
          component="span"
          fontFamily="Montserrat"
          fontSize="0.75rem"
          fontWeight="bold"
          fontStyle="normal"
          textAlign="center"
          color={colors.darkGreen}
          mt="-10px"
        >
          {!current && title}
        </Typography>
      </StyledLink>
    </Box>
  );
};

const LogoContainer = styled("div")`
  width: 7rem;
  margin-top: 2rem;
  img {
    width: 7.7rem;
    padding: 0.75rem;
    object-fit: cover;
  }
`;

export const NavBar = ({ title, menuItems }: NavBarProps) => {
  return (
    <Stack alignContent="flex-start" alignItems="flex-start">
      <LogoContainer>
        <CityIcon />
      </LogoContainer>
      <Box component="nav">
        <Box component="ul" margin={0} padding={0}>
          {menuItems.map((item, idx) => (
            <Fragment key={idx}>
              <NavButton
                icon={item.icon}
                current={title === item.title}
                title={item.title}
                route={item?.route}
              />
            </Fragment>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 10,
          left: 28,
          textAlign: "center",
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 1.5,
        }}
      >
        Powered by: <br />
        EntryPoint <br />
        Networks
      </Box>
    </Stack>
  );
};
