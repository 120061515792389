import React from "react";
import CardWrapper from "../../components/CardWrapper";
import { useGetOperatorFees } from "../../hooks/useGetOperatorFees";
import { useGetEstimatedFees } from "../../hooks/useGetEstimatedFees";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { BillingContext, BillingType } from "../../components/BillingProvider";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { Box, Typography, Grid } from "@mui/material";
import formatNumber from "../../utils/formatNumber";
import PaymentMethod from "../../components/PaymentMethod";
import useViewport from "../../hooks/useViewport";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../App";
import { ForwardArrowIcon } from "../../assets/icons";

const TotalBill = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const currentDate = new Date();
  currentDate.setDate(1);
  currentDate.setMonth(currentDate.getMonth() + 1);
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const navigate = useNavigate();

  const { currentAccount } = React.useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { paymentMethod } = React.useContext(BillingContext) as BillingType;

  const { isMobile } = useViewport();

  const { data: operatorData } = useGetOperatorFees(currentAccount?.id, {
    refetchOnWindowFocus: false,
  });

  const { data: estimateData } = useGetEstimatedFees(currentAccount?.id, {
    refetchOnWindowFocus: false,
  });

  const operatorFees = operatorData?.data?.billing?.subscription?.items?.reduce(
    (sum, item) => {
      return sum + item.price.amount;
    },
    0
  );

  const estimatedFees = estimateData?.data?.subscription?.reduce(
    (sum, item) => {
      return sum + parseFloat(item.Serviceplan.fields.Price);
    },
    0
  );

  const total = (operatorFees || 0) + (estimatedFees || 0);

  const arrowAction = (
    <Link
      to={routes.billing.path}
      aria-label="Payment Info"
      data-cy="paymentInfo"
    >
      <ForwardArrowIcon />
    </Link>
  );

  return (
    <Grid item xl={12}>
      <CardWrapper title="Payment Info" actions={arrowAction}>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(16),
            fontWeight: "400",
            fontStyle: "normal",
            mt: 4,
            letterSpacing: 0,
            textAlign: "left",
            marginBottom: "20px",
            color: colors.darkGreenFontColor,
          }}
        >
          {/* Todo: make due date dynamic! */}
          Your payment of {formatNumber(total, { prefix: "$", decimals: 2 })} is
          due on May 1, 2024.
        </Typography>
        <PaymentMethod {...paymentMethod} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: {
              md: "16px",
              sm: 0,
            },
          }}
        >
          <AutorenewIcon
            sx={{
              color: colors.icons.default.fillColor,
              marginRight: isMobile ? "10px" : "11px",
            }}
          />
          <Typography
            sx={{
              color: colors.textPrimary.heading,
              fontFamily: "Montserrat",
              fontSize: isMobile ? getREMFromPX(10) : getREMFromPX(16),
              fontWeight: 600,
            }}
          >
            Autopay on {formattedDate}
          </Typography>
        </Box>
      </CardWrapper>
    </Grid>
  );
};
export default TotalBill;
