import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)<any>(() => ({
  borderRadius: "0.3rem 0 0 0.3rem",
  fontFamily: "Inter",
  padding: "0.5rem 0.75rem",
  fontSize: "0.75rem",
  fontWeight: 500,
  color: "#55857E",
  backgroundColor: "transparent",
  border: "1px solid #DBDBDB",
  borderRight: 0,
  textTransform: "capitalize",
  width: "40px",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
  "&:disabled": {
    color: "#aeadad",
    backgroundColor: "#FAFAFA",
    cursor: "not-allowed",
  },
  "&:active": {
    backgroundColor: "#DBDBDB",
    color: "#55857E",
    borderColor: "#DBDBDB",
  },
}));

const BackButton: React.FC<ButtonProps> = ({
  children,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      data-cy="previousPageButton"
      aria-label="Go To, Previous Page"
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default BackButton;
