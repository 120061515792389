import _ from "lodash";
import {
  Box,
  Typography,
  Stack,
  Paper,
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Port } from "../../api/interfaces/Port";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import { Account } from "../../api/interfaces/Account";
import MyServicesRow, { MyServicesMobileRow } from "./MyServicesRow";
import sendErrorToast from "../../utils/sendErrorToast";
import { useIsDeviceOn } from "../../hooks/useIsDeviceOn";
import RouterIcon from "@mui/icons-material/Router";
import useViewport from "../../hooks/useViewport";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useContext, useState } from "react";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import EmptyMyServicesMessage from "../../routes/Services/components/EmptyMyServicesMessage";
import StyledLink from "../Link";
import LoaderContent from "../../routes/Services/components/SubscriptionModal/steps/LoaderContent";
import Loader from "../Loader";
import { useGetConfig } from "../../hooks/useGetConfig";

interface MyServicesProps {
  subscriptions: Array<Account>;
  isLoadingSubscriptions: boolean;
}

const MyServices = ({
  subscriptions,
  isLoadingSubscriptions,
}: MyServicesProps) => {
  const { setCurrentDevice, currentDevice, devices } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { data } = useGetConfig();

  const isUserRatingEnabled = Boolean(
    data?.data.config.enabled_features.USER_REVIEWS.enabled
  );

  const optionsDevices = devices?.map((item: any, i: number) => ({
    label: `Device ${i + 1} - ${item?.Physicallocation.house_number} ${
      item?.Physicallocation.street
    }`,
    value: item?.id,
  })) as Array<{
    label: string;
    value: string;
  }>;

  const findDevice = (deviceId: string) =>
    devices.find((device) => device.id === deviceId);

  const listWithoutDuplicatedRecords = optionsDevices.filter(
    (item, index, arr) => {
      const firstIndex = arr.findIndex(
        (element) => element.value === item.value
      );
      return index === firstIndex;
    }
  );

  const { data: isDeviceOn } = useIsDeviceOn(currentDevice.id, {
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast("There was an error getting the device information"),
  });

  const { isMobile } = useViewport();

  const [openDevicesList, setOpenDevicesList] = useState<boolean>(false);

  const currentSubscriptions = subscriptions?.filter(
    (sub: any) => sub.Portinterface.device_id === currentDevice.id
  );

  if (isLoadingSubscriptions) {
    return (
      <Box
        sx={{
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Stack data-cy="myServicesContainer">
      <Typography>
        You can manage your current services on this page. To add more services,
        please visit the "All Services" page to explore and subscribe to
        additional options.
      </Typography>
      <Box
        sx={{
          border: "1px solid #DBDBDB",
          borderRadius: "8px",
          marginTop: "16px",
        }}
      >
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          display="flex"
          alignItems="center"
          marginBottom={!isMobile ? "16px" : ""}
          sx={{
            backgroundColor: theme.new.colors.softGrey,
            padding: 1.5,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <Box marginRight="8px">
            <RouterIcon
              sx={{
                height: 32,
                width: 32,
              }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            onClick={() => setOpenDevicesList(!openDevicesList)}
            sx={{
              cursor: "pointer",
              position: "relative",
            }}
          >
            <Typography
              fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
              fontWeight={700}
              color="#000"
              fontFamily="Inter"
              marginRight="12px"
            >
              {`${currentDevice?.Physicallocation.house_number} ${currentDevice?.Physicallocation.street}`}
            </Typography>

            {devices.length > 1 && (
              <>
                <ArrowDropDownIcon />
                {!!openDevicesList && (
                  <ClickAwayListener
                    onClickAway={() => setOpenDevicesList(false)}
                  >
                    <Paper
                      sx={{
                        position: "absolute",
                        zIndex: 10,
                        padding: "16px 8px",
                        width: isMobile ? "200px" : "237px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 25px 0 rgba(0, 0, 0, 0.25)",
                        top: "34px",
                        left: isMobile ? "20px" : "130px",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          fontSize={getREMFromPX(14)}
                          color={theme.new.colors.darkGreen}
                          fontFamily="Inter"
                          fontWeight={600}
                        >
                          Switch Device
                        </Typography>
                        {listWithoutDuplicatedRecords.map((val) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              marginTop: "20px",
                            }}
                            onClick={() => {
                              const newDevice = findDevice(val.value);
                              if (newDevice) {
                                setCurrentDevice(newDevice);
                              }
                            }}
                            key={val.value}
                          >
                            <RouterIcon />
                            <Typography
                              fontSize={getREMFromPX(14)}
                              color={theme.new.colors.darkGreen}
                              fontFamily="Inter"
                            >
                              {val.label}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Paper>
                  </ClickAwayListener>
                )}
              </>
            )}
          </Box>
          <Typography
            fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(20)}
            marginRight="12px"
          >
            |
          </Typography>
          <Typography
            fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
            fontWeight={500}
            fontFamily="Inter"
            marginRight="6px"
          >
            {`${currentDevice?.serial_number}`}
          </Typography>
          {isDeviceOn && (
            <Box
              sx={{
                height: "20px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 16px",
                marginLeft: "8px",
                backgroundColor:
                  isDeviceOn.data.device === "ON" ? "#84e88e" : "#ff7a00",
              }}
            >
              <Typography
                fontFamily="Inter"
                fontSize={isMobile ? getREMFromPX(7.4) : getREMFromPX(12)}
                color={isDeviceOn.data.device === "ON" ? "#0b8718" : "#fff"}
              >
                {isDeviceOn.data.device === "ON" ? "On" : "Off"}
              </Typography>
            </Box>
          )}

          <StyledLink
            sx={{
              background: "#fff",
              border: `1px solid #D0D5DD`,
              fontSize: 14,
              fontWeight: 500,
              color: theme.new.colors.formGreyDark,
              textAlign: "center",
              borderRadius: "4px",
              width: "fit-content",
              marginLeft: "auto",
              padding: "2px 16px",
            }}
            to="/help/device"
            data-testid="device-info-link"
          >
            Device Info
          </StyledLink>
        </Stack>

        <Box
          sx={{
            padding: "0px 14px 14px 14px",
            td: {
              padding: "0px 16px",
            },
            overflow: "auto",
          }}
        >
          {isMobile ? (
            <>
              {!_.isEmpty(currentSubscriptions)
                ? currentSubscriptions?.map((sub: any, index: number) => {
                    return (
                      <MyServicesMobileRow
                        deviceId={sub?.Portinterface?.device_id}
                        port={{
                          ...(sub.Portinterface as Port),
                          providerId: sub?.Serviceplan?.Service?.provider_id,
                          servicePlan: sub?.Serviceplan,
                        }}
                        unsubscribeId={sub.id}
                        key={sub.id}
                        currentIndex={index}
                        isUserRatingEnabled={isUserRatingEnabled}
                      />
                    );
                  })
                : null}
            </>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Port</TableCell>
                  <TableCell align="center">Provider</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Price</TableCell>
                  {isUserRatingEnabled ? (
                    <TableCell>My Review</TableCell>
                  ) : null}

                  <TableCell
                    sx={{
                      paddingLeft: 6,
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(currentSubscriptions)
                  ? currentSubscriptions?.map((sub: any, index: number) => {
                      return (
                        <MyServicesRow
                          deviceId={sub?.Portinterface?.device_id}
                          port={{
                            ...(sub.Portinterface as Port),
                            providerId: sub?.Serviceplan?.Service?.provider_id,
                            servicePlan: sub?.Serviceplan,
                          }}
                          unsubscribeId={sub.id}
                          key={sub.id}
                          currentIndex={index}
                          dataCy={`my-services-row-${index}`}
                          isUserRatingEnabled={isUserRatingEnabled}
                        />
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          )}

          {_.isEmpty(currentSubscriptions) ? <EmptyMyServicesMessage /> : null}
        </Box>
      </Box>
    </Stack>
  );
};

export default MyServices;
