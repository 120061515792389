import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ProfileSettingsModal } from "../../../components/CustomModal";
import useGetUserId from "../../../hooks/useGetUserId";
import Button from "../../../components/Button";
import getREMFromPX from "../../../utils/getREMFromPX";
import sendErrorToast from "../../../utils/sendErrorToast";
import { theme } from "../../../theme";
import { OnboardingBubble } from "../../../assets/icons";
import { useGetUsersSecurityQuestions } from "../../../hooks/useGetSecurityQuestions";
import { useUpdateUser } from "../../../hooks/useUpdateUser";
import SecurityQuestionSelection from "../../../components/SecurityQuestionSelection";
import { SecurityQuestionsSelection } from "../../../components/SecurityQuestionSelection/SecurityQuestionSelection";

export type SecurityType = {
  question1Option: string;
  question2Option: string;
  question1Answer: string;
  question2Answer: string;
};

const EditSecurityQuestions = () => {
  const colors = theme["new"].colors;

  const userId = useGetUserId();
  const [content, setContent] = useState("edit");
  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  const { data: userSecurityQuestions } = useGetUsersSecurityQuestions(
    undefined,
    userId,
    {
      onError: () =>
        sendErrorToast(
          "There was an error fetching the security questions, please try again"
        ),
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: updateUser, isLoading: isUpdatingUser } = useUpdateUser(
    userId,
    {
      onError: () =>
        sendErrorToast(
          "There was an error saving Security Questions, please try again"
        ),
      onSuccess: () => setContent("success"),
    }
  );

  const handleSubmit = (
    securityQuestionsSelection: SecurityQuestionsSelection
  ) => {
    updateUser({
      security_answer1: securityQuestionsSelection.question1Answer,
      security_answer2: securityQuestionsSelection.question2Answer,
      security_question1: securityQuestionsSelection.question1Option,
      security_question2: securityQuestionsSelection.question2Option,
    });
  };

  const closeModal = () => {
    setIsEditing(false);
    setContent("edit");
  };

  const currentValue = {
    question1Option: userSecurityQuestions?.[0]?.id ?? "",
    question2Option: userSecurityQuestions?.[1]?.id ?? "",
    question1Answer: "",
    question2Answer: "",
  };

  const getContent = () => {
    switch (content) {
      case "success":
        return (
          <Stack sx={{ alignItems: "center" }}>
            <OnboardingBubble />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(20),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "center",
                color: "#000000",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              Your security questions have been updated
            </Typography>
            <Button
              text="OK"
              mode="default"
              disabled={!isValid}
              sx={{ width: "218px" }}
              onClick={() => {
                closeModal();
              }}
            />
          </Stack>
        );
      default:
        return (
          <>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(16),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: colors.darkGreen,
              }}
            >
              Edit your security questions and answers below.
            </Typography>
            <SecurityQuestionSelection
              onSubmit={handleSubmit}
              setIsValid={setIsValid}
              setIsLoadingQuestions={setIsLoadingQuestions}
              previousValues={currentValue}
            >
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                paddingTop={getREMFromPX(theme.spacing * 8)}
              >
                <Button
                  data-testid="saveButton"
                  mode="default"
                  text="Save"
                  type="submit"
                  size="large"
                  disabled={!isValid}
                  isLoading={isUpdatingUser || isLoadingQuestions}
                  sx={{ width: getREMFromPX(220) }}
                />
              </Stack>
            </SecurityQuestionSelection>
          </>
        );
    }
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
      <ProfileSettingsModal title="" open={isEditing} onClose={closeModal}>
        {getContent()}
      </ProfileSettingsModal>
      <Typography
        sx={{
          flex: 1,
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(18),
          fontWeight: "600",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.darkGreenFontColor,
        }}
      >
        Security Questions
      </Typography>
      <Typography
        sx={{
          flex: 1.5,
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(16),
          fontWeight: "500",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.darkGreenFontColor,
        }}
      >
        {userSecurityQuestions?.map((question, idx) => (
          <Stack
            direction="row"
            padding={`${getREMFromPX(theme.spacing * 6)} 0 0 0`}
            key={`security-property-${idx}`}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(16),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                color: colors.darkGreen,
              }}
            >
              {question.question}
            </Typography>
          </Stack>
        ))}
      </Typography>
      <Typography
        onClick={() => setIsEditing(true)}
        sx={{
          cursor: "pointer",
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(16),
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          color: colors.teal,
          paddingLeft: getREMFromPX(10),
        }}
        data-cy="editsecurityquestions-edit"
      >
        Edit
      </Typography>
    </Stack>
  );
};

export default EditSecurityQuestions;
