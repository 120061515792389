import { useContext } from "react";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { Stack, Typography, Checkbox, checkboxClasses } from "@mui/material";
import Button from "../../components/Button";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import { useForm } from "react-hook-form";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { toast } from "react-toastify";

type PropTypes = {
  setContent: Function;
  closeModal: Function;
};

type SettingsPropTypes = {
  label: string;
  emailKey: string;
  portalKey: string;
  form: any;
};

const SettingsRow = ({
  label,
  emailKey,
  portalKey,
  form,
}: SettingsPropTypes) => {
  const colors = theme["new"].colors;

  const useEmail = form.watch(emailKey);
  const usePortal = form.watch(portalKey);

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
      <Typography
        sx={{
          flex: 1,
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(14),
          fontWeight: "500",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.darkGreenFontColor,
        }}
      >
        {label}
      </Typography>
      <Checkbox
        checked={useEmail}
        {...form.register(emailKey)}
        sx={{
          width: getREMFromPX(70),
          transform: "scale(1.3)",
          [`&, &.${checkboxClasses.checked}`]: {
            color: "#488b5b",
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            borderRadius: "50px",
          },
        }}
      />
      <Checkbox
        checked={usePortal || portalKey === "operatorPortal"}
        {...form.register(portalKey)}
        sx={{
          width: getREMFromPX(70),
          transform: "scale(1.3)",
          [`&, &.${checkboxClasses.checked}`]: {
            color: "#488b5b",
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            borderRadius: "50px",
          },
        }}
      />
    </Stack>
  );
};

export const NotificationSettings = ({ setContent, closeModal }: PropTypes) => {
  const colors = theme["new"].colors;

  const { userInfo, setUserInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const labelStyle = {
    fontFamily: "Montserrat",
    fontSize: getREMFromPX(14),
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center",
    color: colors.darkGreenFontColor,
    width: getREMFromPX(70),
  };

  const messaging = userInfo?.data?.user?.messaging;

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      operatorEmail: messaging?.OPERATOR_MESSAGE?.includes("EMAIL"),
      operatorPortal: messaging?.OPERATOR_MESSAGE?.includes("PORTAL"),
      accountEmail: messaging?.ACCOUNT?.includes("EMAIL"),
      accountPortal: messaging?.ACCOUNT?.includes("PORTAL"),
      userEmail: messaging?.USER?.includes("EMAIL"),
      userPortal: messaging?.USER?.includes("PORTAL"),
      subscriptionEmail: messaging?.SUBSCRIPTION?.includes("EMAIL"),
      subscriptionPortal: messaging?.SUBSCRIPTION?.includes("PORTAL"),
      firmwareEmail: messaging?.FIRMWARE?.includes("EMAIL"),
      firmwarePortal: messaging?.FIRMWARE?.includes("PORTAL"),
    },
  });

  const operatorEmail = form.watch("operatorEmail");
  const accountEmail = form.watch("accountEmail");
  const accountPortal = form.watch("accountPortal");
  const userEmail = form.watch("userEmail");
  const userPortal = form.watch("userPortal");
  const subscriptionEmail = form.watch("subscriptionEmail");
  const subscriptionPortal = form.watch("subscriptionPortal");
  const firmwareEmail = form.watch("firmwareEmail");
  const firmwarePortal = form.watch("firmwarePortal");

  const userId = userInfo?.data?.user?.id ?? "";
  const { mutateAsync: updateUser, isLoading } = useUpdateUser(userId, {
    onSuccess: (userData) => {
      toast.success("Notification settings updated successfully!");
      setUserInfo({
        ...userInfo,
        ...userData,
        data: {
          ...userInfo?.data,
          ...userData?.data,
          user: {
            ...userInfo?.data?.user,
            ...userData?.data?.user,
          },
        },
      });
      closeModal();
    },
    onError: () => {
      toast.success("Failed to update notification settings!");
    },
  });

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(16),
          fontWeight: "500",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: "black",
        }}
      >
        How would you like to receive notifications?
      </Typography>
      <Stack sx={{ marginTop: getREMFromPX(25) }}>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: getREMFromPX(10),
          }}
        >
          <Typography sx={labelStyle}>Email</Typography>
          <Typography sx={labelStyle}>Portal</Typography>
        </Stack>
        <SettingsRow
          label="Operator Message"
          emailKey="operatorEmail"
          portalKey="operatorPortal"
          form={form}
        />
        <SettingsRow
          label="Account"
          emailKey="accountEmail"
          portalKey="accountPortal"
          form={form}
        />
        <SettingsRow
          label="User"
          emailKey="userEmail"
          portalKey="userPortal"
          form={form}
        />
        <SettingsRow
          label="Subscription"
          emailKey="subscriptionEmail"
          portalKey="subscriptionPortal"
          form={form}
        />
        <SettingsRow
          label="Firmware Change"
          emailKey="firmwareEmail"
          portalKey="firmwarePortal"
          form={form}
        />
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: getREMFromPX(20),
        }}
      >
        <Typography
          onClick={() => closeModal()}
          sx={{
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(16),
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "right",
            color: colors.green,
          }}
        >
          Close
        </Typography>
        <Button
          mode="default"
          isLoading={isLoading}
          disabled={isLoading}
          text="Save"
          sx={{ width: getREMFromPX(220) }}
          onClick={() => {
            updateUser({
              messaging: {
                OPERATOR_MESSAGE: [
                  "PORTAL",
                  ...(operatorEmail ? ["EMAIL"] : []),
                ],
                ACCOUNT: [
                  ...(accountPortal ? ["PORTAL"] : []),
                  ...(accountEmail ? ["EMAIL"] : []),
                ],
                USER: [
                  ...(userPortal ? ["PORTAL"] : []),
                  ...(userEmail ? ["EMAIL"] : []),
                ],
                SUBSCRIPTION: [
                  ...(subscriptionPortal ? ["PORTAL"] : []),
                  ...(subscriptionEmail ? ["EMAIL"] : []),
                ],
                FIRMWARE: [
                  ...(firmwarePortal ? ["PORTAL"] : []),
                  ...(firmwareEmail ? ["EMAIL"] : []),
                ],
              },
            });
          }}
        />
      </Stack>
    </>
  );
};
