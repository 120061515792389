import { Link, useNavigate } from "react-router-dom";
import CardWrapper from "../../../../components/CardWrapper";
import { BillingIssuesIcon } from "../../../../assets/icons";
import { routes } from "../../../../App";
import { Grid, SxProps } from "@mui/material";
import { ContinueIcon, Description, ImageContainer } from "../../Styles";
import { theme } from "../../../../theme";

const BillingIssues = ({ styles }: { styles: SxProps }) => {
  const navigate = useNavigate();

  const arrowAction = (
    <Link
      to={routes.helpBilling.path}
      aria-label="Goto Billing Help Info page"
      data-cy="help-billing-arrow"
    >
      <ContinueIcon />
    </Link>
  );

  return (
    <CardWrapper title="Billing Issues" actions={arrowAction} sx={styles}>
      <Grid
        container
        spacing={2}
        onClick={() => navigate(routes.helpBilling.path)}
        sx={{
          flexDirection: "row",
          flexWrap: "nowrap",
        }}
      >
        <Grid item sm={6} md={6}>
          <Description>
            Having questions <br /> regarding your bill?
          </Description>
        </Grid>
        <Grid item sm={6} md={6}>
          <ImageContainer>
            <BillingIssuesIcon
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                marginBottom: "1.84rem",
              }}
            />
          </ImageContainer>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};
export default BillingIssues;
