import { Box, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

import Button from "../../components/Button";

const Body = styled(Typography)`
  margin-top: 0.89rem;
  color: var(--neutrals-medium, #a9a9a9);
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  text-align: center;
  margin-top: 4rem;
`;

// export const Button = styled("button")`
//   height: 56px;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   margin: auto;
//   margin-top: 3.5rem;
//   padding: 16px 50px;
//   border-radius: 15px;
//   background-color: #d7f1da;
//   flex-grow: 0;
//   font-family: Montserrat;
//   font-size: 20px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   text-align: center;
//   color: var(--dark-green-font-color, #253331);
//   border: none;
//   cursor: pointer;
//   :hover {
//     box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
//       0px 1px 2px 0px rgba(0, 0, 0, 0.3);
//   }
//   :focus {
//     box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
//       0px 1px 2px 0px rgba(0, 0, 0, 0.3);
//   }
// `;

const EmptyMyServicesMessage = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const navigate = useNavigate();
  return (
    <>
      <Body>You are not subscribed to any services.</Body>
      <Box textAlign="center" style={{ justifyContent: "center" }}>
        <Button
          text="Services"
          mode="secondary"
          onClick={() => navigate(`/services/`)}
          sx={{
            marginBottom: "24px",
            marginTop: "3.5rem",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        />
      </Box>
    </>
  );
};

export default EmptyMyServicesMessage;
