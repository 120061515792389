import CardWrapper from "../../../components/CardWrapper";
import { Typography } from "@mui/material";
import PaymentMethodCard from "../../../components/PaymentMethodCard";
import { theme } from "../../../theme";
import getREMFromPX from "../../../utils/getREMFromPX";
import { PendingStatus } from "../Billing";
import React, { useMemo } from "react";
import {
  BillingContext,
  BillingType,
} from "../../../components/BillingProvider";

export default function PaymentsInfoCard({ title = "Payment Method" }) {
  const { paymentMethod, setOpenModal, verificationRequired } =
    React.useContext(BillingContext) as BillingType;

  const status = useMemo(() => {
    if (verificationRequired) {
      return "Pending";
    } else {
      return "Paid";
    }
  }, [verificationRequired]);

  return (
    <CardWrapper
      title={title}
      status={status}
      isPaymentMethodCard
      actions={
        <Typography
          sx={{
            color:
              status === "Paid"
                ? theme["new"].colors.teal
                : theme["new"].colors.black,
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(16),
            fontWeight: "bold",
            cursor: "pointer",
            padding: "10px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderRadius: "50%",
            },
          }}
          onClick={() => setOpenModal(true)}
        >
          Edit
        </Typography>
      }
    >
      {status === "Pending" ? (
        <PendingStatus />
      ) : (
        <PaymentMethodCard paymentMethod={paymentMethod} status={status} />
      )}
    </CardWrapper>
  );
}
