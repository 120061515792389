import { ReactNode } from "react";
import { Box, Typography, Stack, SxProps } from "@mui/material";
import { theme } from "../../theme";
import { TypesOfStatus } from "../PaymentMethodCard/PaymentMethodCard";
import getREMFromPX from "../../utils/getREMFromPX";

type CardWrapperProps = {
  title: string;
  status?: TypesOfStatus;
  isPaymentMethodCard?: boolean;
  actions?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  sx?: SxProps;
  hasOverflow?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const CardWrapper = ({
  title,
  status,
  actions,
  children,
  footer,
  isPaymentMethodCard = false,
  hasOverflow = false,
  ...rest
}: CardWrapperProps) => {
  const colors = theme["new"].colors;

  return (
    <Box
      {...rest}
      sx={{
        bgcolor: "#F4F4F4",
        borderRadius: "2.1875rem",
        ...rest.sx,
      }}
    >
      <Box
        sx={{
          padding: "1.25rem 1.5625rem 0rem 2.29rem",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            fontFamily="Montserrat"
            fontSize={getREMFromPX(20)}
            fontWeight="600"
            fontStyle="normal"
            letterSpacing={0}
            textAlign="left"
            color={colors.darkGreenFontColor}
          >
            {title}
          </Typography>
          <Stack flexDirection="row" alignItems="flex-end">
            {actions}
          </Stack>
        </Stack>
        {children}
      </Box>
    </Box>
  );
};

export default CardWrapper;
