import { Stack, Typography, Link, Box } from "@mui/material";
import { theme } from "../../theme";
import { WarningTriangleIcon, WarningOctagonIcon } from "../../assets/icons";
import getREMFromPX from "../../utils/getREMFromPX";

type BannerFlavor = "warning" | "error";

interface BannerProps {
  flavor: BannerFlavor;
  message: string;
  action?: () => void;
  actionLabel?: string;
}

const Banner = ({
  flavor = "error",
  message,
  action,
  actionLabel,
}: BannerProps) => {
  const flavorStyles = {
    warning: {
      backgroundColor: theme.new.colors.starYellow,
      color: theme.new.colors.formGreyDark,
    },
    error: {
      backgroundColor: theme["new"].colors.errorRed,
      color: theme.new.colors.white,
    },
  };

  const getIcon = () => {
    switch (flavor) {
      case "warning":
        return <WarningTriangleIcon />;
      case "error":
        return <WarningOctagonIcon width="24" height="24" fill="white" />;
    }
  };

  return (
    <Stack
      direction="row"
      gap={2}
      sx={{
        padding: 1,
        justifyContent: "center",
        alignItems: "center",
        ...flavorStyles[flavor],
      }}
    >
      <Box sx={{ width: "24px", height: "24px" }}>{getIcon()}</Box>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(12),
          fontWeight: "700",
          color: flavorStyles[flavor].color,
        }}
      >
        {message}
      </Typography>
      {actionLabel && (
        <Link
          href="#"
          underline="always"
          onClick={action}
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(12),
            fontWeight: "700",
            color: flavorStyles[flavor].color,
            textDecoration: "underline",
          }}
          style={{ cursor: "pointer" }}
        >
          {actionLabel}
        </Link>
      )}
    </Stack>
  );
};
export default Banner;
