import { Box, Typography, styled, Stack } from "@mui/material";
import { theme } from "../../../theme";
import { Plan } from "../utils/intefaces/Plan";
import { OverviewDataModel } from "../../../components/AllServices/AllServices";
import { FieldIcon } from "../utils/intefaces/FieldIcon";
import getREMFromPX from "../../../utils/getREMFromPX";
import Button from "../../../components/Button";

export const LogoContainer = styled("div")<{
  width: string;
  height: string;
  isCoverImage?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  margin: auto;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: ${(p) =>
      p.isCoverImage
        ? "cover"
        : "contain"}; /* Set to "contain" for fitting height */
  }
`;

const CardService = ({
  plan,
  setOpenSubscribeModal,
  setModalOverviewData,
  setOpenWorkflowSubscriptionModal,
  dataCy,
}: {
  setOpenSubscribeModal: () => void;
  setOpenWorkflowSubscriptionModal: () => void;
  plan: Plan;
  setModalOverviewData: (param: OverviewDataModel) => void;
  dataCy: string;
}) => {
  const setModalData = (plan: Plan) => {
    setModalOverviewData({
      fields: {
        Down: plan.fields?.Down as string,
        LinkSpeed: plan.fields?.LinkSpeed as number,
        Name: plan.fields?.Name as string,
        Price: plan.fields?.Price as string,
        Up: plan.fields?.Up as string,
      },
      description: plan.description as string,
      providerName: plan.providerName as string,
      name: plan.name as string,
      planName: plan.planName as string,
      service_id: plan.service_id,
      provider_id: plan.providerId as string,
      planId: plan.id as string,
      ratings_sum: plan.ratings_sum as string,
      total_reviews: plan.total_reviews as string,
      email: plan.Contacts[0]?.email,
      phone: plan.Contacts[0]?.phone,
      support_url: plan.Contacts[0]?.support_url || plan.support_url,
      subscription_redirect_url: plan.subscription_redirect_url,
      fields_icons: plan.fields_icons as Array<FieldIcon>,
    });
  };

  return (
    <Box
      sx={{
        width: "268px",
        borderRadius: "8px",
        border: `2px solid ${theme.new.colors.minimalGrey}`,
        boxShadow: "0 4px 10px 0 rgba(201, 218, 216, 0.6)",
        position: "relative",
        "&:hover": {
          boxShadow: "0 0 17px 0 rgba(126, 130, 129, 0.39)",
          shadowOpacity: 1,
        },
        cursor: "pointer",
      }}
      data-cy={dataCy}
    >
      <Stack gap={1.6} sx={{ padding: "8px 20px 14px 20px" }}>
        <LogoContainer width="180" height="60">
          <img
            src={`${process.env.PUBLIC_URL}/assets/provider/${plan.providerId}.png`}
            alt=""
            role="presentation"
            style={{ width: "120%" }}
          />
        </LogoContainer>
        <Box
          sx={{
            width: "fit-content",
            background: "#F2F4F7",
            borderRadius: "16px",
            padding: "2px 8px 2px 8px",
          }}
        >
          <Typography
            color={theme.new.colors.darkGreenFontColor}
            fontSize={10}
            fontFamily="Inter"
            textAlign="center"
            fontWeight={500}
          >
            {plan.name}
          </Typography>
        </Box>

        <Typography
          color={theme.new.colors.darkGreenFontColor}
          fontFamily="Inter"
          fontSize={getREMFromPX(14)}
          fontWeight={700}
          lineHeight="normal"
        >
          {plan.planName}
        </Typography>
        <Typography
          color={"#6D6D6D"}
          fontFamily="Inter"
          fontSize={getREMFromPX(12)}
          fontWeight={700}
          lineHeight="normal"
          height={12}
        >
          {plan.providerName}
        </Typography>
        <Box>
          <Typography
            color={theme.new.colors.darkGreenFontColor}
            fontFamily="Inter"
            fontSize={20}
            fontWeight={600}
          >
            ${plan.fields?.Price}/Month
          </Typography>
          <Typography
            fontWeight={600}
            color={"#6D6D6D"}
            fontFamily="Inter"
            fontSize={16}
            sx={{
              textWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Up: {plan.fields?.Up}mb / Down: {plan.fields?.Down}mb
          </Typography>
        </Box>
        <Typography
          color={"#6D6D6D"}
          fontSize={getREMFromPX(12)}
          fontWeight={600}
          lineHeight="normal"
          height={34}
          sx={{
            textWrap: "wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {plan.description}{" "}
        </Typography>
      </Stack>
      <Box
        sx={{
          borderBottomLeftRadius: "6px",
          borderBottomRightRadius: "6px",
          display: "flex",
          height: "40px",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.new.colors.minimalGrey,
          marginTop: "auto",
        }}
      >
        <Typography
          color={theme.new.colors.darkGreenFontColor}
          fontSize={14}
          fontFamily="Inter"
          fontWeight="bold"
          textAlign="center"
          textTransform="uppercase"
          display="flex"
          alignItems="center"
        >
          <Box
            component="button"
            onClick={() => {
              setModalData(plan);
              setOpenSubscribeModal();
            }}
            sx={{
              width: "100px",
              height: "20px",
              fontSize: "12px",
              padding: 0,
              border: "none",
              backgroundColor: "transparent",
              color: "#1D19E0",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            data-cy={`service-view-more-${
              plan.providerName
                ? plan.providerName.toLowerCase().replaceAll(" ", "-") + "-"
                : ""
            }${
              plan.planName
                ? plan.planName.toLowerCase().replaceAll(" ", "-")
                : ""
            }`}
          >
            View More
          </Box>
          <Button
            text="Subscribe"
            onClick={() => {
              setModalData(plan);
              setOpenWorkflowSubscriptionModal();
            }}
            sx={{
              width: "120px",
              height: "20px",
              fontSize: "14px",
              borderRadius: "4px",
              marginLeft: "16px",
              background: "white",
              padding: "14px 10px",
              border: "1px solid #D0D5DD",
              color: theme.new.colors.darkGreenFontColor,
              textTransform: "initial",
              ":hover": {
                border: "1px solid #D0D5DD",
                background: "white",
              },
              fontWeight: 600,
            }}
            data-cy={`service-plan-card-${
              plan.providerName
                ? plan.providerName.toLowerCase().replaceAll(" ", "-") + "-"
                : ""
            }${
              plan.planName
                ? plan.planName.toLowerCase().replaceAll(" ", "-")
                : ""
            }`}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default CardService;
