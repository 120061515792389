import { useContext } from "react";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NavContainer";
import CardWrapper from "../../../../components/CardWrapper";
import { BackStyle } from "../../Styles";
import { Grid, Typography } from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { OnboardingBubble } from "../../../../assets/icons";
import { VbgTitle, Router, Hardware, ViewMyService } from "./Styles";
import { ButtonService } from "../../Styles";
import getREMFromPX from "../../../../utils/getREMFromPX";
import useViewport from "../../../../hooks/useViewport";
import RouterIcon from "@mui/icons-material/Router";
import { useIsPortLive } from "../../../../hooks/useIsPortLive";
import { DeviceBackFace, DeviceFrontFace } from "../../../../assets/device";

const DeviceTroubleshooting = () => {
  const backAction = (
    <BackStyle aria-label="Back to Help" to={routes.help.path}>
      Back to Help
    </BackStyle>
  );

  const { currentDevice } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const wanPorts = currentDevice?.Portinterfaces?.filter(
    (portinterface) =>
      portinterface.display === true && portinterface.type === "WAN"
  );

  const wanPort = wanPorts ? wanPorts[0] : null;

  const { data: livePort } = useIsPortLive(
    wanPort?.device_id ?? "",
    wanPort?.id ?? ""
  );

  const portWithLive = {
    ...wanPort,
    ...livePort,
  } as any;

  const wanPortActive =
    portWithLive?.data?.portinterface.live?.link_state === "up" ? true : false;

  const deviceStatus = wanPortActive ? (
    <ButtonService
      style={{
        margin: "0",
        fontSize: "0.875rem",
        display: "inline-flex",
        height: "48px",
        backgroundColor: "#84e88e",
        cursor: "auto",
      }}
    >
      Device Connected
    </ButtonService>
  ) : (
    <ButtonService
      style={{
        margin: "0",
        fontSize: "0.875rem",
        display: "inline-flex",
        height: "48px",
        backgroundColor: "#e8e8e8",
        cursor: "auto",
      }}
    >
      Device is Disabled
    </ButtonService>
  );

  const { isMobile } = useViewport();

  return (
    <NavContainer title="Help">
      <CardWrapper
        title="Troubleshooting"
        actions={backAction}
        sx={{ overflowX: "auto" }}
      >
        <Grid container>
          <Grid item md={12} lg={6}>
            <VbgTitle>Having Trouble?</VbgTitle>
            <div
              style={{
                fontWeight: "600",
                fontSize: "16px",
                marginLeft: "16px",
              }}
            >
              Follow these steps below
            </div>
            <Typography
              fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
              fontWeight={500}
              color="#000"
              fontFamily="Montserrat"
              marginRight="5.4px"
              marginLeft="20px"
              marginTop="17px"
              display="flex"
              gap={2}
              alignItems="center"
            >
              <Router>
                <RouterIcon />
              </Router>
              <Hardware>{currentDevice?.hardware_version}</Hardware>
            </Typography>
            <Grid item style={{ marginTop: "67px", marginLeft: "16px" }}>
              <p>
                <OnboardingBubble
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "16px",
                    float: "left",
                    marginTop: "-8px",
                  }}
                />
                <div>Make sure device is plugged in</div>
              </p>
            </Grid>
            <Grid item style={{ marginTop: "58px", marginLeft: "16px" }}>
              <p>
                <OnboardingBubble
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "16px",
                    float: "left",
                  }}
                />
                <div>
                  Power button should be blue.
                  <br /> If it is red, push until the button is blue.
                </div>
              </p>
            </Grid>
            <Grid item style={{ marginTop: "58px", marginLeft: "16px" }}>
              <p>
                <OnboardingBubble
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "16px",
                    float: "left",
                    marginTop: "-8px",
                  }}
                />
                <div>S2 is the fiber port, these lights should be lit</div>
              </p>
            </Grid>
            <Grid item style={{ marginTop: "58px", marginLeft: "16px" }}>
              <p>
                <OnboardingBubble
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "16px",
                    float: "left",
                    marginTop: "-8px",
                  }}
                />
                <div>Console and USB are not used</div>
              </p>
            </Grid>
            <Grid
              item
              style={{
                fontSize: "0.875rem",
                marginRight: "16px",
                marginTop: "210px",
              }}
            >
              <p>
                Having trouble with your services? Please contact your service
                provider.
              </p>
            </Grid>
          </Grid>
          <Grid item md={12} lg={6} sx={{ zIndex: 2 }}>
            <ViewMyService
              to={routes.helpDevice.path}
              aria-label="View Device info"
              sx={{
                display: "block",
                width: "fit-content",
                float: "none",
                marginBottom: "70px",
                marginTop: "40px",
                marginLeft: "auto",
              }}
            >
              Device Info
            </ViewMyService>
            <div
              style={{
                marginLeft: isMobile ? 0 : getREMFromPX(-90),
                marginRight: isMobile ? getREMFromPX(90) : 0,
                textAlign: "center",
              }}
            >
              <p
                style={{
                  marginBottom: "38px",
                  fontSize: "1.25rem",
                  fontWeight: "bolder",
                }}
              >
                Front
              </p>
              <DeviceFrontFace
                style={{
                  marginBottom: "28px",
                  marginLeft: isMobile ? getREMFromPX(48) : 0,
                }}
              />
              <p style={{ fontSize: "1.25rem", fontWeight: "bolder" }}>Back</p>
              <DeviceBackFace
                style={{ marginLeft: isMobile ? 0 : getREMFromPX(-48) }}
              />
            </div>
            <div
              style={{
                textAlign: "right",
                marginTop: "150px",
                marginRight: "39px",
              }}
            >
              {deviceStatus}
            </div>
          </Grid>
        </Grid>
      </CardWrapper>
    </NavContainer>
  );
};
export default DeviceTroubleshooting;
