import { Box, Fade, IconButton, Modal, Typography } from "@mui/material";
import React, { FC } from "react";
import { theme } from "../../theme";
import useViewport from "../../hooks/useViewport";
import getREMFromPX from "../../utils/getREMFromPX";
import CloseIcon from "@mui/icons-material/Close";

const modalStyles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 15.5px",
  },
  paper: {
    backgroundColor: theme["new"].colors.white || "#ffffff",
    minWidth: getREMFromPX(510),
    flexGrow: 0,
    objectFit: "contain",
    borderRadius: getREMFromPX(20),
    outline: "none",
    boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.25)",
    position: "relative",
    overflow: "auto",
    maxHeight: "90%",
  },
  closeButton: {
    position: "absolute",
    top: 15,
    right: 15,
    color: theme["new"].colors.green,
  },
};

interface CustomModalProps {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  title?: string;
}

export const ProfileSettingsModal: FC<CustomModalProps> = ({
  open,
  onClose,
  children,
  title,
}) => {
  const colors = theme["new"].colors;
  const { isMobile } = useViewport();
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={modalStyles.modal}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...modalStyles.paper,
            padding: !isMobile ? "80px 40px 30px" : "16px 16px",
            flexDirection: "row",
          }}
        >
          {!!title && (
            <Typography
              sx={{
                position: "absolute",
                top: 30,
                left: 40,
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(20),
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: colors.darkGreenFontColor,
              }}
            >
              {title}
            </Typography>
          )}
          <IconButton
            sx={{
              position: "absolute",
              top: 15,
              right: 15,
              color: colors.teal,
            }}
            onClick={onClose}
            data-cy="profile-mydetails-close"
          >
            <CloseIcon sx={{ fontWeight: "bolder" }} fontSize="large" />
          </IconButton>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};
