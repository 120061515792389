import React from "react";
import { Box, Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useViewport from "../../hooks/useViewport";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";

interface SelectButtonProps {
  content: string;
  disabled?: boolean;
  mode: string;
  onClick: () => void;
  sx?: React.CSSProperties;
  dataCy?: string;
}

const SelectButton = ({
  content,
  disabled,
  mode,
  onClick,
  sx,
  dataCy,
}: SelectButtonProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { isMobile } = useViewport();

  return (
    <Button
      data-cy={dataCy}
      onClick={onClick}
      sx={{
        padding: "4px 10px 4px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        borderRadius: "4px",
        fontSize: isMobile ? "8px" : "14px",
        lineHeight: "18px",
        backgroundColor: "#fff",
        fontFamily: "Inter",
        fontWeight: 600,
        position: "relative",
        color: "#313131",
        textTransform: "capitalize",
        height: "100%",
        width: "fit-content",
        border: `1px solid #D0D5DD`,
        "&:focus": {
          border: `1px solid ${theme.new.colors.gray}`,
        },
        "&:hover": {
          backgroundColor: !!disabled
            ? colors.buttons.selectButton.disabledHvButtonBg
            : mode === "default"
            ? "#fafafa"
            : "#fff",
        },
        "&:active": {
          backgroundColor: !!disabled
            ? colors.buttons.selectButton.disabled.ActiveButtonBg
            : mode === "default"
            ? "#C5DCC8"
            : "#fff",
        },
        ...sx,
      }}
    >
      {content}
      <Box marginTop="4px">
        <KeyboardArrowDownIcon />
      </Box>
    </Button>
  );
};

export default SelectButton;
