import { Box, Paper, Typography } from "@mui/material";
import { SortFilterLabel } from "./interfaces";
import { theme } from "../../theme";

export interface OptionField {
  option: string;
  isActive: boolean;
  value: SortFilterLabel;
}

export const menuOptions: Array<OptionField> = [
  {
    option: "Price: Low to High",
    isActive: false,
    value: "priceLowToHigh",
  },
  {
    option: "Price: High to Low",
    isActive: false,
    value: "priceHighToLow",
  },
  {
    option: "Rating",
    isActive: false,
    value: "rating",
  },
  {
    option: "Provider",
    isActive: false,
    value: "provider",
  },
];

const FiltersMenu = ({
  setSortFilter,
  sortByOptions,
  setSortByOptions,
}: {
  setSortFilter: (v: SortFilterLabel | null) => void;
  setSortByOptions: (o: Array<OptionField>) => void;
  sortFilter?: SortFilterLabel | null;
  sortByOptions: Array<OptionField>;
}) => {
  const handleSelectFilter = (val: number) => {
    const newList = sortByOptions.map((b, i) => ({
      ...b,
      isActive: i === val ? !b.isActive : false,
    })) as Array<OptionField>;

    const selectedSortFilter = newList.find(
      (val: OptionField) => val.isActive
    )?.value;

    setSortFilter(selectedSortFilter ?? "rating");
    setSortByOptions(newList);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Paper
        sx={{
          position: "absolute",
          zIndex: 10,
          padding: "16px 0px",
          width: "200px",
          borderRadius: "10px",
          boxShadow: "0 4px 25px 0 rgba(0, 0, 0, 0.25)",
          marginTop: "12px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {sortByOptions.map(({ option, isActive }, idx) => (
            <Typography
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="500"
              lineHeight="36px"
              color={isActive ? theme.new.colors.darkGreen : "#626262"}
              sx={{
                cursor: "pointer",
                fontWeight: isActive
                  ? theme.fonts.weights.bolder
                  : theme.fonts.weights.normal,
                "&:hover": { background: theme.new.colors.hoverColor },
                padding: "0px 8px",
              }}
              onClick={() => handleSelectFilter(idx)}
              key={option}
            >
              {option}
            </Typography>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

export default FiltersMenu;
