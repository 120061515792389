import { useContext } from "react";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NavContainer";
import CardWrapper from "../../../../components/CardWrapper";
import { BackStyle } from "../../Styles";
import {
  SupportInfo,
  iconStyles,
  IconText,
  ServiceProviderMultiSubHeader,
  ServiceProviderMultDesc,
} from "../../Styles";
import { useGetMySubscriptions } from "../../../../hooks/useGetMySubscriptions";
import useGetUserId from "../../../../hooks/useGetUserId";
import sendErrorToast from "../../../../utils/sendErrorToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { LogoContainer } from "../../../../routes/Services/components/CardService";
import { Box } from "@mui/material";
import { Language, Email, LocalPhone } from "@mui/icons-material";
import { Subscription } from "../../../../api/interfaces/Subscription";

const ServiceProviders = () => {
  const userId = useGetUserId();

  const { selectedAccountId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data: subscriptionsData } = useGetMySubscriptions(
    userId,
    selectedAccountId,
    {
      enabled: !!userId && !!selectedAccountId,
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast(
          "There was an error getting the subscriptions information, please try again"
        ),
    }
  );

  const backAction = (
    <BackStyle aria-label="Back to Help" to={routes.help.path}>
      Back to Help
    </BackStyle>
  );

  return (
    <NavContainer title="Help">
      <CardWrapper title="Service Provider Info" actions={backAction}>
        <ServiceProviderMultiSubHeader>
          Contact Your Service Providers
        </ServiceProviderMultiSubHeader>
        <ServiceProviderMultDesc>
          For any issues with your services. Please directly contact your
          service provider through the contacts below.
        </ServiceProviderMultDesc>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns:
              "repeat(auto-fill, minmax(max(100px, 320px), 1fr))",
            gridGap: "28px",
          }}
        >
          {subscriptionsData?.data?.subscription?.map(
            (subscription: Subscription) => {
              const support_url =
                subscription?.Serviceplan?.Service?.support_url;
              const email =
                subscription?.Serviceplan?.Service?.Provider?.Contacts?.at(
                  0
                )?.email;
              const phone =
                subscription?.Serviceplan?.Service?.Provider?.Contacts?.at(
                  0
                )?.phone;

              return (
                <Box
                  key={subscription.id}
                  padding="20px"
                  sx={{
                    borderRadius: "8px",
                    maxHeight: "100%",
                    border: "1px solid #DBDBDB",
                    "&:hover": {
                      boxShadow: "0 0 17px 0 rgba(126, 130, 129, 0.39)",
                      shadowOpacity: 1,
                    },
                  }}
                >
                  <LogoContainer
                    width="234"
                    height="100%"
                    sx={{
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/provider/${subscription?.Serviceplan?.Service.Provider?.id}.png`}
                      role="presentation"
                      alt="Service Providers Logo"
                    />
                  </LogoContainer>
                  {support_url ? (
                    <SupportInfo>
                      <Language sx={iconStyles} />
                      <IconText
                        href={support_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {support_url}
                      </IconText>
                    </SupportInfo>
                  ) : null}
                  {email ? (
                    <SupportInfo>
                      <Email sx={iconStyles} />
                      <IconText href={`mailto:${email}`}>{email}</IconText>
                    </SupportInfo>
                  ) : null}
                  {phone ? (
                    <SupportInfo>
                      <LocalPhone sx={iconStyles} />
                      <IconText
                        sx={{ color: "#000", textDecoration: "none" }}
                        href={`tel:+1${phone}`}
                      >
                        {phone}
                      </IconText>
                    </SupportInfo>
                  ) : null}
                </Box>
              );
            }
          )}
        </Box>
      </CardWrapper>
    </NavContainer>
  );
};
export default ServiceProviders;
