import { Box, Card, Typography, styled } from "@mui/material";
import NewButton from "../../../components/Button";
import React from "react";
import { theme } from "../../../theme";
import StarIcon from "@mui/icons-material/Star";
import { Plan } from "../utils/intefaces/Plan";
import { convertToDecimalIfNeeded } from "../utils/convertToDecimal";
import { OverviewDataModel } from "../../../components/AllServices/AllServices";
import { FieldIcon } from "../utils/intefaces/FieldIcon";
import getREMFromPX from "../../../utils/getREMFromPX";

export const LogoContainer = styled("div")<{
  width: string;
  height: string;
  isCoverImage?: boolean;
}>`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  img {
    width: ${(p) => p.width}px;
    height: ${(p) => p.height}px;
    padding: 12px;
  }
`;

const ListCardMobile = ({
  plan,
  setModalOverviewData,
  setOpenSubscribeModal,
  setOpenWorkflowSubscriptionModal,
}: {
  setOpenSubscribeModal: () => void;
  setOpenWorkflowSubscriptionModal: () => void;
  plan: Plan;
  setModalOverviewData: (param: OverviewDataModel) => void;
}) => {
  const setModalData = (plan: Plan) => {
    setModalOverviewData({
      fields: {
        Down: plan.fields?.Down as string,
        LinkSpeed: plan.fields?.LinkSpeed as number,
        Name: plan.fields?.Name as string,
        Price: plan.fields?.Price as string,
        Up: plan.fields?.Up as string,
      },
      description: plan.description as string,
      providerName: plan.providerName as string,
      name: plan.name as string,
      planName: plan.planName as string,
      service_id: plan.service_id,
      provider_id: plan.providerId as string,
      planId: plan.id as string,
      ratings_sum: plan.ratings_sum as string,
      total_reviews: plan.total_reviews as string,
      email: plan.Contacts[0]?.email,
      phone: plan.Contacts[0]?.phone,
      support_url: plan.support_url,
      subscription_redirect_url: plan.subscription_redirect_url,
      fields_icons: plan.fields_icons as Array<FieldIcon>,
    });
  };

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: "8px 0",
        alignItems: "center",
        padding: "15px 12px",
        gap: "8px",
        boxShadow: "0 4px 10px 0 rgba(201, 218, 216, 0.6)",
        borderRadius: "10px",
      }}
      onClick={() => {
        setModalData(plan);
        setOpenSubscribeModal();
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <img
            width="78px"
            height="23px"
            src={`${process.env.PUBLIC_URL}/assets/provider/${plan.providerId}.png`}
            alt=""
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "8px auto 0px",
            }}
          >
            {Number(plan.total_reviews) === 0 ? (
              <Typography
                fontFamily="Montserrat"
                fontSize={getREMFromPX(8)}
                color={theme.new.colors.darkGreen}
              >
                No reviews
              </Typography>
            ) : (
              <>
                <StarIcon
                  sx={{
                    color: "#f9cb28",
                    marginRight: "4.9px",
                    width: "15.1px",
                  }}
                />
                <Typography
                  color={theme.new.colors.darkGreenFontColor}
                  fontFamily="Montserrat"
                  fontWeight={500}
                  fontSize="11px"
                >
                  {convertToDecimalIfNeeded(
                    Number(plan.ratings_sum) / Number(plan.total_reviews)
                  )}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography
          fontFamily="Montserrat"
          fontSize="8px"
          fontWeight="600"
          color={theme.new.colors.mediumGrey}
          marginBottom="8px"
        >
          {plan.name}
        </Typography>
        <Typography
          fontFamily="Montserrat"
          fontSize="8px"
          fontWeight="500"
          marginBottom="8px"
          color={theme.new.colors.darkGreenFontColor}
        >
          {plan.planName}
        </Typography>
        <Typography
          fontFamily="Montserrat"
          fontSize="10px"
          fontWeight="bold"
          color={theme.new.colors.darkGreenFontColor}
        >
          ${plan.fields?.Price}
        </Typography>
      </Box>
      <Box>
        <NewButton
          text="Subscribe"
          mode="default"
          onClick={() => {
            setModalData(plan);
            setOpenWorkflowSubscriptionModal();
          }}
          sx={{
            width: "72px",
            height: "23px",
            fontSize: "9px",
            fontWeight: 600,
          }}
        />
      </Box>
    </Card>
  );
};

export default ListCardMobile;
