import NavContainer from "../../components/NavContainer";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { theme } from "../../theme";
import { useContext, useState, useEffect } from "react";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import useViewport from "../../hooks/useViewport";
import AllServices from "../../components/AllServices";
import MyServices from "../../components/MyServices";
import history from "../../utils/history";
import { useSearchParams } from "react-router-dom";
import CardWrapper from "../../components/CardWrapper";
import { routes } from "../../App";

const getTabOptions = (all: boolean = true) => [
  {
    label: "All Services",
    isActive: all,
  },
  {
    label: "My Services",
    isActive: !all,
  },
];

const Services = () => {
  const { currentDevice, currentAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const { isMobile } = useViewport();
  const tabOptions = getTabOptions(!tab || tab === "All Services");
  const [tabs, setTabs] = useState(tabOptions);
  const [showLoading, setShowLoading] = useState(true);
  const handleSelectTab = (idx: number) => {
    const changedTabs = tabs.map((val, i) => ({
      ...val,
      isActive: i === idx,
    }));

    setTabs(changedTabs);
    history.push({
      pathname: routes.services.path,
      search: `?tab=${tabOptions[idx].label}`,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 2000);
  }, []);

  return (
    <NavContainer
      title="Services"
      options={tabs}
      eventHandler={handleSelectTab}
    >
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <CardWrapper>
            {isMobile && (
              <Box display="flex" alignItems="center" marginBottom="8px">
                {tabs.map(({ label, isActive }, idx) => (
                  <Typography
                    key={idx}
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontWeight={isActive ? "bold" : "600"}
                    color={
                      isActive
                        ? theme.new.colors.darkGreenFontColor
                        : theme.new.colors.grey
                    }
                    marginRight={idx === 0 ? "30px" : ""}
                    onClick={() => handleSelectTab(idx)}
                  >
                    {label}
                  </Typography>
                ))}
              </Box>
            )}

            {currentAccount && currentDevice ? (
              <>
                {tabs.at(0)?.isActive ? (
                  <AllServices currentAccount={currentAccount} />
                ) : (
                  <MyServices
                    currentAccount={currentAccount}
                    currentDevice={currentDevice}
                  />
                )}
              </>
            ) : showLoading ? (
              <Backdrop open={true}>
                <CircularProgress
                  data-testid="progressSpinner"
                  color="inherit"
                />
              </Backdrop>
            ) : (
              !currentDevice && (
                <Typography>
                  It appears that you don't have a device on your account
                </Typography>
              )
            )}
          </CardWrapper>
        </Grid>
      </Grid>
    </NavContainer>
  );
};

export default Services;
