import NavContainer from "../../components/NavContainer/NavContainer";
import { Box } from "@mui/material";
import Notifications from "./Notifications";
import UserDetails from "./UserDetails/UserDetails";
import SecurityDetails from "./SecurityDetails";
import PaymentsInfoCard from "../Billing/components/PaymentsInfoCard";
import DeviceInfo from "./DeviceInfo";
import { useContext } from "react";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { useGetConfig } from "../../hooks/useGetConfig";

const UserProfile = () => {
  const { currentDevice } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  return (
    <NavContainer title="Account Settings">
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 4,
        }}
      >
        <Box display="flex" flex={1} gap={4} flexDirection="column">
          <UserDetails />
          <SecurityDetails />
        </Box>
        <Box display="flex" flex={1} gap={4} flexDirection="column">
          <Notifications />
          {currentDevice ? <DeviceInfo device={currentDevice} /> : null}
          {showBilling ? (
            <PaymentsInfoCard title="Payment Information" />
          ) : null}
        </Box>
      </Box>
    </NavContainer>
  );
};

export default UserProfile;
